"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    content: {},
    loginForm: {
        paddingHorizontal: (0, scale_1.scale)(16),
        marginVertical: (0, scale_1.scale)(24),
    },
    textTitle: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
        color: "#292A33",
        marginBottom: (0, scale_1.scale)(8)
    },
    textTime: {
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: "#7C7C7C",
        marginBottom: (0, scale_1.scale)(12),
    },
    textDes: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
        color: "#292A33",
        marginBottom: (0, scale_1.scale)(8)
    },
    mainDetail: {
        marginHorizontal: (0, scale_1.scale)(16),
        marginVertical: (0, scale_1.scale)(24),
    },
    bottomButton: {
        position: "absolute",
        right: (0, scale_1.scale)(16),
        bottom: (0, scale_1.scale)(90),
        height: (0, scale_1.scale)(40),
        width: (0, scale_1.scale)(40),
        alignItems: "center",
    },
    err: {
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
        width: "100%",
        textAlign: "left",
    },
});
