"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconRemoveImage = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(26), height: props.height || (0, scale_1.scale)(26), viewBox: "0 0 26 26", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_12593_9262", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "26", height: "26" },
        React.createElement(react_native_svg_1.Rect, { width: "26", height: "26", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_12593_9262)" },
        React.createElement(react_native_svg_1.Path, { d: "M12.9987 14.1516L9.48198 17.6683C9.32504 17.8252 9.13476 17.9019 8.91114 17.8985C8.68754 17.895 8.49727 17.8148 8.34033 17.6579C8.18339 17.5009 8.10492 17.3089 8.10492 17.0818C8.10492 16.8548 8.18339 16.6628 8.34033 16.5058L11.8466 12.9995L8.3299 9.50992C8.17297 9.35298 8.09624 9.16096 8.09972 8.93388C8.10319 8.7068 8.18339 8.51478 8.34033 8.35785C8.49727 8.20091 8.68928 8.12244 8.91637 8.12244C9.14343 8.12244 9.33544 8.20091 9.49238 8.35785L12.9987 11.8746L16.4883 8.35785C16.6452 8.20091 16.8355 8.12244 17.0591 8.12244C17.2827 8.12244 17.473 8.20091 17.6299 8.35785C17.798 8.52591 17.882 8.7207 17.882 8.94222C17.882 9.16375 17.798 9.35298 17.6299 9.50992L14.1236 12.9995L17.6403 16.5162C17.7973 16.6732 17.8758 16.8634 17.8758 17.087C17.8758 17.3106 17.7973 17.5009 17.6403 17.6579C17.4723 17.8259 17.2775 17.9099 17.056 17.9099C16.8344 17.9099 16.6452 17.8259 16.4883 17.6579L12.9987 14.1516Z", fill: "white" }))));
exports.default = IconRemoveImage;
