"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
    },
    body: {
        flex: 1,
    },
    articleInfo: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        borderTopLeftRadius: (0, scale_1.scale)(16),
        borderTopRightRadius: (0, scale_1.scale)(16),
        backgroundColor: '#FEFEFE',
        shadowColor: '#0000001A',
        shadowOffset: {
            width: 0,
            height: -(0, scale_1.scale)(4),
        },
        shadowOpacity: 1,
        shadowRadius: (0, scale_1.scale)(12),
        elevation: 6,
    },
    articleInfoHeader: {
        width: '100%',
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(8),
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: (0, scale_1.scale)(1),
        borderBottomColor: '#DDDDDD',
    },
    articleInfoTitle: {
        flex: 1,
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    articleInfoBody: {
        paddingTop: (0, scale_1.scale)(16),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    articleInfoBodyLabel: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
});
