"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    content: {},
    loginForm: {
        paddingHorizontal: (0, scale_1.scale)(16),
        marginVertical: (0, scale_1.scale)(24),
    },
    mainLogin: {
        paddingHorizontal: (0, scale_1.scale)(16),
        marginVertical: (0, scale_1.scale)(24),
        justifyContent: 'center',
        alignItem: 'center',
    },
    textTitle: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: "#292A33",
    },
    textNoTitle: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: "#292A33",
        textAlign: 'center'
    },
    textNoDes: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: "#292A33",
        textAlign: 'center'
    },
    textHide: {
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(19),
        color: "#292A33",
        marginRight: (0, scale_1.scale)(8),
    },
    textTitleTime: {
        fontSize: (0, scale_1.moderateScale)(18),
        lineHeight: (0, scale_1.moderateScale)(27),
        color: "#292A33",
    },
    mainCheck: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: (0, scale_1.scale)(12)
    },
    buttonHideShowNoti: {
        paddingHorizontal: (0, scale_1.scale)(12),
        paddingVertical: (0, scale_1.scale)(4),
        borderRadius: (0, scale_1.scale)(30),
        backgroundColor: '#dddddd',
        marginLeft: (0, scale_1.scale)(24),
        flexDirection: 'row'
    },
    bottomButton: {
        position: "absolute",
        right: (0, scale_1.scale)(16),
        bottom: (0, scale_1.scale)(90),
        height: (0, scale_1.scale)(40),
        width: (0, scale_1.scale)(40),
        alignItems: "center",
    },
    err: {
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
        width: "100%",
        textAlign: "left",
    },
});
