"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
function IconQuestion({ width = (0, scale_1.scale)(20), height = (0, scale_1.scale)(20), ...props }) {
    return (React.createElement(react_native_svg_1.default, { width: width, height: height, viewBox: "0 0 20 20", fill: "none", ...props },
        React.createElement(react_native_svg_1.Mask, { id: "a", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: width, height: height },
            React.createElement(react_native_svg_1.Path, { fill: "#D9D9D9", d: "M0 0H20V20H0z" })),
        React.createElement(react_native_svg_1.G, { mask: "url(#a)" },
            React.createElement(react_native_svg_1.Path, { d: "M9.991 14.68a.82.82 0 00.603-.247.822.822 0 00.246-.604.82.82 0 00-.246-.603.822.822 0 00-.604-.246.82.82 0 00-.603.247.821.821 0 00-.246.603.82.82 0 00.247.603.822.822 0 00.603.246zm.01 3.236a7.712 7.712 0 01-3.087-.623A7.995 7.995 0 014.4 15.601a7.994 7.994 0 01-1.693-2.513 7.705 7.705 0 01-.623-3.087c0-1.095.208-2.124.623-3.088A7.996 7.996 0 014.4 4.4a7.995 7.995 0 012.513-1.692A7.705 7.705 0 0110 2.083c1.095 0 2.124.208 3.088.623.963.416 1.801.98 2.514 1.692a7.994 7.994 0 011.693 2.513c.415.963.623 1.992.623 3.087a7.711 7.711 0 01-.623 3.088 7.996 7.996 0 01-1.692 2.514 7.996 7.996 0 01-2.513 1.693 7.705 7.705 0 01-3.087.623zm0-1.25c1.86 0 3.437-.646 4.729-1.937 1.291-1.292 1.937-2.868 1.937-4.73 0-1.86-.646-3.437-1.937-4.728C13.438 3.979 11.86 3.333 10 3.333c-1.86 0-3.437.646-4.729 1.938C3.98 6.562 3.334 8.139 3.334 10c0 1.86.646 3.437 1.937 4.729 1.292 1.291 2.868 1.937 4.73 1.937zm.067-10.234c.384 0 .717.121.998.364.281.242.422.544.422.907 0 .306-.09.58-.27.82-.18.242-.384.467-.615.675a5.3 5.3 0 00-.799.886c-.23.322-.353.68-.37 1.076a.471.471 0 00.161.381.564.564 0 00.392.154c.16 0 .296-.053.408-.16a.73.73 0 00.213-.393c.055-.286.174-.541.355-.765.18-.224.377-.437.588-.638a5.6 5.6 0 00.795-.969c.227-.35.34-.74.34-1.17 0-.66-.26-1.202-.78-1.627a2.796 2.796 0 00-1.822-.637c-.496 0-.963.11-1.403.33-.439.22-.78.54-1.025.96a.571.571 0 00-.073.408c.027.143.1.252.22.326a.643.643 0 00.468.072.704.704 0 00.409-.274 1.97 1.97 0 01.613-.527c.24-.132.498-.199.775-.199z", fill: "#292A33" }))));
}
exports.default = IconQuestion;
