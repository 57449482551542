"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconRemoveHashtag = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(21), height: props.height || (0, scale_1.scale)(21), viewBox: "0 0 21 21", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_11171_2407", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "21", height: "21" },
        React.createElement(react_native_svg_1.Rect, { width: "21", height: "21", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_11171_2407)" },
        React.createElement(react_native_svg_1.Path, { d: "M10.4992 11.4215L6.06023 15.8605C5.93909 15.9816 5.78681 16.0436 5.60339 16.0464C5.41999 16.0492 5.26492 15.9872 5.13816 15.8605C5.01138 15.7337 4.948 15.58 4.948 15.3994C4.948 15.2188 5.01138 15.0652 5.13816 14.9384L9.5771 10.4994L5.13816 6.06048C5.017 5.93933 4.95502 5.78705 4.95222 5.60364C4.94941 5.42024 5.01138 5.26516 5.13816 5.1384C5.26492 5.01163 5.41859 4.94824 5.59919 4.94824C5.77979 4.94824 5.93347 5.01163 6.06023 5.1384L10.4992 9.57734L14.9382 5.1384C15.0593 5.01724 15.2116 4.95526 15.395 4.95246C15.5784 4.94965 15.7335 5.01163 15.8602 5.1384C15.987 5.26516 16.0504 5.41884 16.0504 5.59944C16.0504 5.78004 15.987 5.93372 15.8602 6.06048L11.4213 10.4994L15.8602 14.9384C15.9814 15.0595 16.0434 15.2118 16.0462 15.3952C16.049 15.5786 15.987 15.7337 15.8602 15.8605C15.7335 15.9872 15.5798 16.0506 15.3992 16.0506C15.2186 16.0506 15.0649 15.9872 14.9382 15.8605L10.4992 11.4215Z", fill: props?.color || "#ADADAD" }))));
exports.default = IconRemoveHashtag;
