"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPLOAD_VIDEO_INTRO_TIMEOUT = exports.DEFAULT_NAME = exports.REGEX_HASHTAG = exports.REGEX_PHONE_NUMBER_JP = exports.REGEX_LEAST_1_SPECIAL_CHARACTER = exports.REGEX_PASSWORD = exports.REGEX_EMAIL = exports.urlDemo = exports.listenerEvents = exports.LANGUAGE_CODE = void 0;
exports.LANGUAGE_CODE = {
    vi: 'vi',
    ja: 'ja',
    en: 'en',
};
exports.default = {
    ACCESS_TOKEN_LOCAL: 'ACCESS_TOKEN_LOCAL',
    REFRESH_TOKEN_LOCAL: 'REFRESH_TOKEN_LOCAL',
    FIREBASE_TOKEN: 'FIREBASE_TOKEN',
    IN_ACTIVE: 'Inactive',
    ACTIVE: 'Active',
    FCM_TOKEN: 'FCM_TOKEN',
    IS_COMPLETED_TUTORIAL: 'IS_COMPLETED_TUTORIAL',
    FIREBASE_MESSAGING_TOKEN: 'FIREBASE_MESSAGING_TOKEN',
    AUTH_EMAIL: 'AUTH_EMAIL',
    REASON_DELETE: 'REASON_DELETE',
    CHANGE_PHONE_NUMBER: 'CHANGE_PHONE_NUMBER',
};
exports.listenerEvents = {
    PROFILE_REACHED_END: 'PROFILE_REACHED_END',
    FOLLOW_COUNTER_UPDATE: 'FOLLOW_COUNTER_UPDATE',
    FOLLOWER_COUNTER_UPDATE: 'FOLLOWER_COUNTER_UPDATE',
    MIND_MAP_NODE_PRESS: 'MIND_MAP_NODE_PRESS',
    TOTAL_HASHTAG_DETAIL: 'TOTAL_HASHTAG_DETAIL',
    ON_NOTIFICATION_CLICK: 'ON_NOTIFICATION_CLICK',
};
exports.urlDemo = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0kc_TLq5-Re1X8tZ5m9ZRgMdAjKWObY8DeA&usqp=CAU';
exports.REGEX_EMAIL = /^[^\s@.][^\s@]*@[^\s@]+\.[^\s@]+$/;
exports.REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d.#@$!%^*?&()<>+=?/_-]{10,50}$/;
exports.REGEX_LEAST_1_SPECIAL_CHARACTER = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/;
('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0kc_TLq5-Re1X8tZ5m9ZRgMdAjKWObY8DeA&usqp=CAU');
exports.REGEX_PHONE_NUMBER_JP = /^(?:\+\d{1,2}|0)\d{1,4}\d{1,4}\d{4}$/;
exports.REGEX_HASHTAG = /(^|[\s　\]|[＃#])([＃#][\wｰーぁ-ゖァ-ヺー一-龯]+(?![\wｰーぁ-ゖァ-ヺー一-龯]*[^\wｰーぁ-ゖァ-ヺー一-龯\s　\n]))/g;
exports.DEFAULT_NAME = 'お名前未設定';
exports.UPLOAD_VIDEO_INTRO_TIMEOUT = 60 * 1000;
