"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconHistory = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22), viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7295_2155", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22) },
        React.createElement(react_native_svg_1.Rect, { width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22), fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7295_2155)" },
        React.createElement(react_native_svg_1.Path, { d: "M10.9824 17.7519C10.2431 17.2066 9.4487 16.7853 8.59902 16.4879C7.74933 16.1906 6.8691 16.0419 5.95831 16.0419C5.14507 16.0419 4.35651 16.1583 3.59262 16.391C2.82873 16.6237 2.08953 16.9339 1.375 17.3217V5.35224C2.05546 4.91507 2.78615 4.60129 3.56707 4.41091C4.34799 4.22052 5.14507 4.12533 5.95831 4.12533C6.8503 4.12533 7.72114 4.25167 8.57081 4.50434C9.42048 4.75702 10.2302 5.11253 11 5.57086V16.0984C11.758 15.6189 12.5624 15.2605 13.4133 15.023C14.2641 14.7857 15.1403 14.667 16.0416 14.667C16.5916 14.667 17.134 14.7128 17.6687 14.8045C18.2035 14.8961 18.7305 15.0366 19.25 15.2258V4.69297C19.4944 4.7764 19.7336 4.86924 19.9674 4.97148C20.2013 5.07372 20.4205 5.20064 20.625 5.35224V17.3217C19.9104 16.9339 19.1712 16.6237 18.4073 16.391C17.6434 16.1583 16.8549 16.0419 16.0416 16.0419C15.125 16.0419 14.2359 16.1862 13.3745 16.4747C12.5131 16.7632 11.7157 17.1889 10.9824 17.7519ZM12.8333 13.7503V5.50033L17.4166 0.916992V9.6253L12.8333 13.7503Z", fill: "#FEFEFE" }))));
exports.default = IconHistory;
