"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontCheck1 = exports.fontCheck = exports.isTabletDevices = exports.isPC = exports.height = exports.width = exports.ratioW = exports.moderateScale = exports.verticalScale = exports.scale = exports.checkDeviceScreen = exports.DeviceWidth = exports.DeviceScreen = void 0;
const react_native_1 = require("react-native");
const react_native_device_info_1 = require("react-native-device-info");
// const { width, height } = Dimensions.get('window')
const { width: _width, height } = react_native_1.Dimensions.get('window');
exports.height = height;
const width = react_native_1.Platform.OS === 'web' ? (_width >= 1024 ? 375 : _width) : _width;
exports.width = width;
const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];
const isTabletDevices = () => {
    if (react_native_1.Platform.OS === 'android') {
        return longDimension / shortDimension <= 1.6;
    }
    return (0, react_native_device_info_1.isTablet)();
};
exports.isTabletDevices = isTabletDevices;
const isPC = react_native_1.Platform.OS === 'web' && _width >= 1024;
exports.isPC = isPC;
//Guideline sizes are based on standard ~5" screen mobile device and 7,9" screen tablet & ipad
const guidelineBaseWidth = isTabletDevices() ? 768 : 375;
const guidelineBaseHeight = isTabletDevices() ? 1024 : 667;
const scale = (size) => (shortDimension / guidelineBaseWidth) * size;
exports.scale = scale;
const verticalScale = (size) => (longDimension / guidelineBaseHeight) * size;
exports.verticalScale = verticalScale;
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;
exports.moderateScale = moderateScale;
const ratioW = (size) => (shortDimension * size) / 100;
exports.ratioW = ratioW;
const fontCheck = (font) => {
    return isTabletDevices() ? moderateScale(font * 1.2) : scale(font);
};
exports.fontCheck = fontCheck;
const fontCheck1 = (font) => {
    return isTabletDevices() ? moderateScale(font * 1.2) : scale(font * 1.2);
};
exports.fontCheck1 = fontCheck1;
exports.DeviceScreen = {
    MOBILE: 'mobile',
    UL_MOBILE: 'ultra_mobile',
    TABLET: 'tablet',
    LAPTOP: 'laptop',
    DESkTOP: 'desktop',
    ULTRA_DESkTOP: 'ul_desktop',
};
exports.DeviceWidth = {
    MOBILE: 375,
    UL_MOBILE: 450,
    TABLET: 768,
    LAPTOP: 1024,
    DESkTOP: 1200,
};
const checkDeviceScreen = (dynamicWidth) => {
    if (dynamicWidth <= exports.DeviceWidth.MOBILE) {
        return exports.DeviceScreen.MOBILE;
    }
    else if (dynamicWidth <= exports.DeviceWidth.UL_MOBILE) {
        return exports.DeviceScreen.UL_MOBILE;
    }
    else if (dynamicWidth <= exports.DeviceWidth.TABLET) {
        return exports.DeviceScreen.TABLET;
    }
    else if (dynamicWidth <= exports.DeviceWidth.LAPTOP) {
        return exports.DeviceScreen.MOBILE;
    }
    else if (dynamicWidth <= exports.DeviceWidth.DESkTOP) {
        return exports.DeviceScreen.MOBILE;
    }
    else {
        return exports.DeviceScreen.MOBILE;
    }
};
exports.checkDeviceScreen = checkDeviceScreen;
