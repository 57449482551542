"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconSearch = (props) => (React.createElement(react_native_svg_1.default, { width: props?.width || (0, scale_1.scale)(21), height: props?.height || (0, scale_1.scale)(21), viewBox: "0 0 21 22", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_11171_13510", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "21", height: "22" },
        React.createElement(react_native_svg_1.Rect, { y: "0.5", width: "21", height: "21", fill: "black" })),
    React.createElement(react_native_svg_1.Path, { d: "M8.32749 14.1652C6.83327 14.1652 5.5679 13.647 4.53137 12.6105C3.49483 11.5739 2.97656 10.3085 2.97656 8.81431C2.97656 7.32009 3.49483 6.05471 4.53137 5.01817C5.5679 3.98164 6.83327 3.46338 8.32749 3.46338C9.82172 3.46338 11.0871 3.98164 12.1236 5.01817C13.1602 6.05471 13.6784 7.32009 13.6784 8.81431C13.6784 9.43915 13.5736 10.0359 13.3638 10.6047C13.154 11.1734 12.8741 11.6682 12.5241 12.0888L17.5587 17.1234C17.6799 17.2446 17.7419 17.3969 17.7447 17.5803C17.7475 17.7637 17.6855 17.9188 17.5587 18.0455C17.432 18.1723 17.2783 18.2357 17.0977 18.2357C16.9171 18.2357 16.7634 18.1723 16.6366 18.0455L11.602 13.0109C11.1645 13.3721 10.6614 13.6548 10.0926 13.859C9.52388 14.0632 8.9355 14.1652 8.32749 14.1652ZM8.32749 12.8528C9.4549 12.8528 10.4098 12.4616 11.1923 11.6791C11.9747 10.8967 12.366 9.94172 12.366 8.81431C12.366 7.6869 11.9747 6.73197 11.1923 5.94952C10.4098 5.16706 9.4549 4.77583 8.32749 4.77583C7.20009 4.77583 6.24515 5.16706 5.4627 5.94952C4.68026 6.73197 4.28904 7.6869 4.28904 8.81431C4.28904 9.94172 4.68026 10.8967 5.4627 11.6791C6.24515 12.4616 7.20009 12.8528 8.32749 12.8528Z", fill: props?.color || "#C8C8C8" })));
exports.default = IconSearch;
