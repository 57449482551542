"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("./actions");
const initState = {
    listBlock: [],
    isLoading: false,
    error: null,
};
function listBlockReducer(state = initState, action) {
    switch (action.type) {
        case actions_1.LIST_BLOCK_START:
            return {
                ...state,
                isLoading: true,
            };
        case actions_1.LIST_BLOCK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listBlock: action.data,
            };
        case actions_1.LIST_BLOCK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.data,
            };
        default:
            return state;
    }
}
exports.default = listBlockReducer;
