"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
    },
    loginForm: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
    },
    input: {
        height: 40,
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'gray',
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    buttonContainer: {
        width: '100%',
        backgroundColor: 'red',
        justifyContent: 'center',
        height: 40,
        marginTop: 15,
        borderRadius: 20,
        paddingHorizontal: 10,
    },
    viewLike: {
        flexDirection: 'row',
        height: (0, scale_1.scale)(44),
        paddingHorizontal: (0, scale_1.scale)(16),
        borderColor: '#DDDDDD',
        width: '100%',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        marginTop: (0, scale_1.scale)(16),
        alignItems: 'center',
    },
    bottomButton: {
        position: 'absolute',
        right: (0, scale_1.scale)(16),
        bottom: (0, scale_1.scale)(90),
        height: (0, scale_1.scale)(40),
        width: (0, scale_1.scale)(40),
        alignItems: 'center',
    },
    textTimeNow: {
        fontSize: (0, scale_1.moderateScale)(9),
        lineHeight: (0, scale_1.moderateScale)(14),
        color: '#FEFEFE',
        backgroundColor: '#000000B2',
        paddingHorizontal: (0, scale_1.scale)(2),
        top: (0, scale_1.scale)(25),
        position: 'absolute',
        zIndex: 99,
        borderRadius: (0, scale_1.scale)(2),
        textAlign: 'center',
        left: 0,
        maxWidth: 200,
    },
});
