"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
        position: 'relative',
    },
    body: {
        flex: 1,
    },
    articleImage: {
        width: '100%',
        height: (0, scale_1.scale)(210),
        backgroundColor: '#F7F7F7',
    },
    articleInfo: {
        width: '100%',
        padding: (0, scale_1.scale)(16),
    },
    articleTitle: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    hashTagList: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    hashTagItem: {
        marginTop: (0, scale_1.scale)(8),
        marginRight: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#007AFF',
    },
    authorInfo: {
        flexDirection: 'row',
        marginTop: (0, scale_1.scale)(16),
    },
    authorAvatar: {
        width: (0, scale_1.scale)(42),
        height: (0, scale_1.scale)(42),
        marginRight: (0, scale_1.scale)(12),
        borderRadius: 999,
        overflow: 'hidden',
    },
    authorName: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    authorDate: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#7C7C7C',
    },
    articleStatus: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: (0, scale_1.scale)(16),
    },
    articleStatusLeft: {
        flex: 1,
        flexDirection: 'row',
    },
    statusNumber: {
        marginRight: (0, scale_1.scale)(16),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    statusUnit: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#545454',
    },
    statusRow: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(8),
        alignItems: 'center',
        borderTopWidth: (0, scale_1.scale)(1),
        borderBottomWidth: (0, scale_1.scale)(1),
        borderColor: '#DDDDDD',
    },
    commentButton: {
        marginRight: (0, scale_1.scale)(32),
    },
    articleContent: {
        width: '100%',
        marginTop: (0, scale_1.scale)(16),
        paddingHorizontal: (0, scale_1.scale)(16),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        fontFamily: 'Hiragino Kaku Gothic ProN',
        color: '#292A33',
    },
    articleContentLabel: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    articleContentImage: {
        marginTop: (0, scale_1.scale)(12),
        width: (0, scale_1.scale)(300),
        height: (0, scale_1.scale)(168.75),
        borderRadius: (0, scale_1.scale)(8),
        alignSelf: 'center',
    },
    rootArticle: {
        marginTop: (0, scale_1.scale)(12),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    rootArticleTitle: {
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#545454',
    },
    rootArticleBody: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: (0, scale_1.scale)(12),
    },
    rootArticleInfo: {
        flex: 1,
        justifyContent: 'center',
    },
    rootArticleAuthorName: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    rootArticleAuthorId: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#7C7C7C',
    },
    rootArticleButton: {
        width: (0, scale_1.scale)(96),
        height: (0, scale_1.scale)(32),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(3),
        marginLeft: (0, scale_1.scale)(20),
    },
    inheritArticles: {
        marginTop: (0, scale_1.scale)(20),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    inheritArticleItem: {
        width: '100%',
        marginTop: (0, scale_1.scale)(8),
        paddingHorizontal: (0, scale_1.scale)(8),
        paddingVertical: (0, scale_1.scale)(12),
        borderWidth: (0, scale_1.scale)(1),
        borderColor: '#DDDDDD',
        borderRadius: (0, scale_1.scale)(3),
        backgroundColor: '#FEFEFE',
    },
    inheritArticleItemLeft: {
        flex: 1,
        marginRight: (0, scale_1.scale)(12),
    },
    inheritArticleItemTitle: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    inheritArticleHashtag: {
        marginRight: (0, scale_1.scale)(8),
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#007AFF',
    },
    inheritArticleItemAuthorAvatar: {
        width: (0, scale_1.scale)(26),
        height: (0, scale_1.scale)(26),
        borderRadius: 999,
    },
    inheritArticleItemAuthor: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    inheritArticleItemImage: {
        width: (0, scale_1.scale)(112),
        height: (0, scale_1.scale)(63),
        borderRadius: (0, scale_1.scale)(3),
    },
    inheritArticleItemNote: {
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#7C7C7C',
    },
    inheritArticleButton: {
        width: (0, scale_1.scale)(165.5),
        height: (0, scale_1.scale)(30),
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(3),
        // borderColor: '#1960B0',
    },
    inheritArticleButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#FEFEFE',
    },
    relativeHashtag: {
        width: '100%',
        marginTop: (0, scale_1.scale)(20),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    relativeHashtagBody: {
        width: '100%',
        marginTop: (0, scale_1.scale)(8),
        padding: (0, scale_1.scale)(12),
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(3),
        borderColor: '#DDDDDD',
        backgroundColor: '#EEEEEE',
    },
    relativeHashtagText: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    suggestArticles: {
        width: '100%',
        marginTop: (0, scale_1.scale)(20),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    suggestArticleItem: {
        width: '100%',
        flexDirection: 'row',
        marginTop: (0, scale_1.scale)(8),
        paddingHorizontal: (0, scale_1.scale)(8),
        paddingVertical: (0, scale_1.scale)(12),
        backgroundColor: '#FEFEFE',
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(3),
        borderColor: '#DDDDDD',
    },
    suggestArticleItemTitle: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    suggestArticleItemComment: {
        marginLeft: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#7C7C7C',
    },
    suggestArticleItemImage: {
        width: (0, scale_1.scale)(112),
        height: (0, scale_1.scale)(63),
        borderRadius: (0, scale_1.scale)(3),
        borderWidth: (0, scale_1.scale)(1),
        borderColor: '#CCCCCC',
    },
    navigateOtherArticle: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: (0, scale_1.scale)(20),
        backgroundColor: '#FEFEFE',
    },
    navigateButton: {
        flex: 1,
        height: '100%',
        paddingVertical: (0, scale_1.scale)(8),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    navigateButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#545454',
    },
    navigateButtonRow: {
        width: '100%',
        height: (0, scale_1.scale)(36),
        flexDirection: 'row',
        marginTop: (0, scale_1.scale)(8),
        marginBottom: (0, scale_1.scale)(21),
        alignItems: 'center',
    },
    navigateArticleLabel: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(10),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    navigateArticleDecor: {
        height: (0, scale_1.scale)(84),
        width: (0, scale_1.scale)(1),
        backgroundColor: '#DDDDDD',
    },
    bottomButtonItem: {
        position: 'absolute',
        right: (0, scale_1.scale)(16),
        width: (0, scale_1.scale)(36),
        height: (0, scale_1.scale)(36),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 999,
        borderWidth: (0, scale_1.scale)(1),
        borderColor: '#EDEDED',
        backgroundColor: '#FEFEFE',
        shadowColor: '#0000000A',
        shadowOffset: {
            width: 0,
            height: (0, scale_1.scale)(4),
        },
        shadowRadius: (0, scale_1.scale)(4),
        elevation: 4,
    },
    textTimeNow: {
        fontSize: (0, scale_1.moderateScale)(9),
        lineHeight: (0, scale_1.moderateScale)(14),
        color: '#FEFEFE',
        backgroundColor: '#000000B2',
        paddingHorizontal: (0, scale_1.scale)(2),
        top: (0, scale_1.scale)(23),
        position: 'absolute',
        zIndex: 99,
        borderRadius: (0, scale_1.scale)(2),
        textAlign: 'center',
        left: -4,
        maxWidth: 200,
    },
});
