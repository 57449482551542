"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const icons_1 = require("../../../../assets/icons");
const images_1 = require("../../../../assets/images");
const components_1 = require("../../../../components");
const helpler_1 = require("../../../../utils/helpler");
const scale_1 = require("../../../../utils/scale");
const AvatarEditor = (props) => {
    const { defaultSource, onChange } = props;
    const [avatarSource, setAvatarSource] = (0, react_1.useState)(defaultSource);
    const [imagePickedSource, setImagePickedSource] = (0, react_1.useState)('');
    const [imagePickedType, setImagePickedType] = (0, react_1.useState)('image/jpeg');
    const modalVisible = !!imagePickedSource;
    const onAvatarPress = () => {
        if (!helpler_1.isWeb || !!imagePickedSource)
            return;
        const inputElement = document.getElementById("avatar-file-upload");
        if (inputElement) {
            inputElement.click();
        }
    };
    const handlePickImage = (event) => {
        const file = event.target.files?.[0];
        if (!file)
            return;
        const isValid = (0, helpler_1.validateFile)(file, 5, 'image');
        if (!isValid)
            return;
        // @ts-ignore
        const url = URL.createObjectURL(file);
        setImagePickedSource(url);
        setImagePickedType(file.type);
    };
    const handleApplyImageChange = (imageUrl) => {
        if (imageUrl) {
            setAvatarSource(imageUrl);
            onChange(imageUrl);
        }
        setImagePickedSource('');
    };
    return (react_1.default.createElement(react_native_1.Pressable, { style: styles.avatarButton, onPress: onAvatarPress },
        helpler_1.isWeb &&
            react_1.default.createElement("input", { type: "file", accept: "image/png, image/jpeg, image/jpg, image/webp", value: "", onChange: handlePickImage, style: { display: "none" }, id: "avatar-file-upload" }),
        react_1.default.createElement(react_native_1.Image, { source: avatarSource ? { uri: avatarSource } : images_1.images.AvatarDefaul, resizeMode: "cover", style: styles.avatar }),
        react_1.default.createElement(react_native_1.View, { style: [styles.avatarAbsolute] },
            react_1.default.createElement(icons_1.IC_ADD_IMAGE, { width: (0, scale_1.scale)(30), height: (0, scale_1.scale)(30) })),
        react_1.default.createElement(components_1.ModalCropImage, { visible: modalVisible, imageSource: imagePickedSource, imageType: imagePickedType, onClose: handleApplyImageChange, cropSize: { width: (0, scale_1.scale)(280), height: (0, scale_1.scale)(280) }, cropShape: 'round', saveButtonLabel: '\u4FDD\u5B58' })));
};
exports.default = AvatarEditor;
const styles = react_native_1.StyleSheet.create({
    avatarButton: {
        position: "relative",
        width: (0, scale_1.scale)(60),
        height: (0, scale_1.scale)(60),
        borderWidth: (0, scale_1.scale)(4),
        borderRadius: 999,
        borderColor: "#F7F7F7",
        marginTop: -(0, scale_1.scale)(16),
    },
    avatar: {
        width: "100%",
        height: "100%",
        borderRadius: 999,
    },
    avatarAbsolute: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        borderRadius: 999,
    },
});
