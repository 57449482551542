"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    main: {
        paddingTop: (0, scale_1.scale)(25),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    Rectangle: {
        width: 6,
        height: 24,
        backgroundColor: "#0591E8",
    },
    buttonSend: {
        marginTop: (0, scale_1.scale)(32),
        width: (0, scale_1.scale)(343),
        backgroundColor: "#FEFEFE",
        borderColor: "#1960B0",
        borderRadius: 8,
        borderWidth: 1,
        height: (0, scale_1.verticalScale)(48),
        justifyContent: "center",
        paddingHorizontal: 20,
        alignItems: "center",
    },
    loginForm: {
        justifyContent: "center",
        alignItems: "center",
    },
    input: {
        width: (0, scale_1.scale)(343),
    },
    loginTextButton: {
        color: "white",
        textAlign: "center",
        fontWeight: "600",
    },
    buttonContainer: {
        width: "100%",
        backgroundColor: "red",
        justifyContent: "center",
        height: 40,
        marginTop: 15,
        borderRadius: 20,
        paddingHorizontal: 10,
    },
    imageContainer: {
        position: "absolute",
        top: 30,
        resizeMode: "contain",
        height: "30%",
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
        height: 50,
        borderRadius: 25,
    },
    underlineButton: {
        color: "black",
        textDecorationLine: "underline",
        fontWeight: "600",
        paddingBottom: 20,
        fontFamily: "Hiragino Mincho ProN",
    },
    textButton: {
        color: "white",
        fontWeight: "600",
        fontFamily: "Hiragino Mincho ProN",
    },
    textBottom: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: "300",
        color: "#292A33",
        textDecorationLine: "underline",
    },
    err: {
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
        width: "100%",
        textAlign: "left",
    },
});
