"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconTimeLine = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22), viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_4244_41357", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22) },
        React.createElement(react_native_svg_1.Rect, { width: (0, scale_1.scale)(22), height: (0, scale_1.scale)(22), fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_4244_41357)" },
        React.createElement(react_native_svg_1.Path, { d: "M17.6917 8.18093L13.7958 4.33092L15.0792 3.04759C15.4306 2.6962 15.8622 2.52051 16.374 2.52051C16.8858 2.52051 17.3174 2.6962 17.6688 3.04759L18.9521 4.33092C19.3035 4.68231 19.4868 5.10627 19.5021 5.6028C19.5174 6.09933 19.3493 6.52329 18.9979 6.87468L17.6917 8.18093ZM16.3625 9.53301L6.64583 19.2497H2.75V15.3538L12.4667 5.63718L16.3625 9.53301Z", fill: "#FEFEFE" }))));
exports.default = IconTimeLine;
