"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepLinkConfigure = void 0;
exports.deepLinkConfigure = {
    screens: {
        MAINTABBAR: {
            path: '',
            initialRouteName: '',
            screens: {
                HOMENAVIGATOR: {
                    path: 'home',
                    initialRouteName: '/',
                    screens: {
                        HOME: '',
                        HOME_ARTICLE_DETAIL: {
                            path: 'post',
                        },
                        DETAIL_STORY: 'detail-story/:id',
                        LIST_COMMENT_ARTICLE: {
                            path: 'list_comment_article',
                            initialRouteName: '/',
                        },
                        DETAIL_COMMENT_ARTICLE: {
                            path: 'detail-comment',
                            initialRouteName: '/',
                        },
                        MIND_MAP_SCREEN: 'mind-map',
                        URL_NOT_FOUND: 'not-found',
                    },
                },
                NOTI: {
                    path: 'notification',
                    initialRouteName: '/',
                    screens: {
                        NOTIFICATION_SCREEN: '',
                        DETAIL_NOTI_SCREEN: {
                            path: 'detail',
                        },
                        DETAIL_STORY: 'detail-story/:id',
                        LIST_COMMENT_ARTICLE: {
                            path: 'list_comment_article',
                            initialRouteName: '/',
                        },
                        DETAIL_COMMENT_ARTICLE: {
                            path: 'detail-comment',
                            initialRouteName: '/',
                        },
                        MIND_MAP_SCREEN: 'mind-map',
                        HOME_ARTICLE_DETAIL: 'post',
                        URL_NOT_FOUND: 'not-found',
                    },
                },
                SEARCH_ARTICLE_TAP: {
                    path: 'article',
                    initialRouteName: '/',
                    screens: {
                        SEARCH_ARTICLE: {
                            path: 'search-article',
                            initialRouteName: '/',
                            screens: {
                                NEW_ARTICLE_LIST: 'new-article-list',
                                LIST_ARTICLE_USER: 'list_user',
                                LIST_HASHTAG: 'list_hashtag',
                            },
                        },
                        LIST_COMMENT_ARTICLE: {
                            path: 'list_comment_article',
                            initialRouteName: '/',
                        },
                        DETAIL_COMMENT_ARTICLE: {
                            path: 'detail-comment',
                            initialRouteName: '/',
                        },
                        DETAIL_STORY: 'detail-story/:id',
                        MIND_MAP_SCREEN: 'mind-map',
                        URL_NOT_FOUND: 'not-found',
                    },
                },
                SEARCH_HISTORY: {
                    path: 'history',
                    initialRouteName: '/',
                    screens: {
                        HISTORY_SEARCH_SCREEN: {
                            path: 'search-history',
                            initialRouteName: '/',
                            screens: {
                                NEW_ARTICLE_LIST: '',
                                LIST_ARTICLE_USER: 'list_user',
                                LIST_HASHTAG: 'list_hashtag',
                            },
                        },
                        DETAIL_STORY: 'detail-story/:id',
                        LIST_COMMENT_ARTICLE: {
                            path: 'list_comment_article',
                            initialRouteName: '/',
                        },
                        DETAIL_COMMENT_ARTICLE: {
                            path: 'detail-comment',
                            initialRouteName: '/',
                        },
                        MIND_MAP_SCREEN: 'mind-map',
                        URL_NOT_FOUND: 'not-found',
                    },
                },
                HIDDEN_TAB_NAVIGATOR: {
                    path: '/',
                    initialRouteName: '/',
                    screens: {
                        USER_PROFILE: {
                            path: 'users/:userHandle',
                            initialRouteName: '/',
                        },
                        MY_PROFILE: {
                            path: 'my-page',
                            initialRouteName: '/',
                            screens: {},
                        },
                        SETTING_NAVIGATOR: {
                            path: 'settings',
                            initialRouteName: '/',
                            screens: {
                                CHANGE_PASSWORD: 'change-password',
                                TIMELINE_NOTIFY_SETTING: 'timeline-notify-settings',
                                ARTICLE_NOTIFY_SETTING: 'article-notify-settings',
                                PERSONAL_HISTORY_NOTIFY_SETTING: 'personal-history-notify-settings',
                                ACCOUNT_LIMIT_SCREEN: 'limit-accounts',
                                ACCOUNT_BLOCK_OVERVIEW: 'block-account-overview',
                                ACCOUNT_MUTE_OVERVIEW: 'mute-account-overview',
                                URL_NOT_FOUND: 'not-found',
                            },
                        },
                        NOTIFY_SETTING: {
                            path: 'settings-notify',
                            initialRouteName: '/',
                        },
                        ARTICLE_NOTIFY_SETTING: {
                            path: 'settings-pushnoti-article',
                            initialRouteName: '/',
                        },
                        PERSONAL_HISTORY_NOTIFY_SETTING: {
                            path: 'settings-pushnoti-personal-history',
                            initialRouteName: '/',
                        },
                        TIMELINE_NOTIFY_SETTING: {
                            path: 'settings-pushnoti-timeline',
                            initialRouteName: '/',
                        },
                        FAQ_SCREEN: 'faq',
                        POLICY_SCREEN: 'policy',
                        POLICY_DETAIL_SCREEN: 'policy-detail/:index/:itemIndex',
                        COMPANY_INFO: 'company-info',
                        ACCOUNT_LIMIT_SCREEN: {
                            path: 'limit-accounts/:defaultTabIndex',
                            initialRouteName: '/',
                            screens: {
                                BLOCK_USER_SETTING: 'block',
                                MUTE_USER_SETTING: 'mute',
                            },
                        },
                        HASHTAG: {
                            path: 'hashtag/:typeHashTag/',
                            screens: {
                                all: 'all',
                                article: 'article',
                                history: 'history',
                                timeline: 'timeline',
                                profile: 'profile',
                            },
                        },
                        HASHTAG_DETAIL: {
                            path: 'hashtag-detail/:hashtag/:typeScreen/:typeHashTag/',
                            screens: {
                                article: 'article',
                                history: 'history',
                                timeline: 'timeline',
                                profile: 'profile',
                                HASHTAG: 'hashtag/:hashtag',
                            },
                        },
                        FOLLOW_SCREEN: {
                            path: 'follow-users/:userHandle',
                            screens: {
                                FOLLOW: 'following',
                                FOLLOWERS: 'follower',
                            },
                        },
                        FOLLOW_TAG: 'follow-tag',
                        HOME_ARTICLE_DETAIL: {
                            path: 'post',
                        },
                        MY_PAGE_SCREEN: 'setting',
                        DELETE_ACCOUNT_SCREEN: 'delete-account',
                        DISPLAY_INFORMATION_SCREEN: 'phone-number-and-birthday',
                        EDIT_PHONE_SCREEN: 'phone-number-edit',
                        VERIFY_PHONE_SCREEN: 'verify-phone-number',
                        // EDIT_BIRTHDAY_SCREEN: 'birthday-edit',
                        EDIT_PHONE_SUCCESS_SCREEN: 'phone-number-edit-success',
                        DETAIL_STORY: 'detail-story/:id',
                        LIST_COMMENT_ARTICLE: {
                            path: 'list_comment_article',
                            initialRouteName: '/',
                        },
                        DETAIL_COMMENT_ARTICLE: {
                            path: 'detail-comment',
                            initialRouteName: '/',
                        },
                        MIND_MAP_SCREEN: 'mind-map',
                        DELETE_ACCOUNT_ONE_SCREEN: 'dalete-account-first',
                        DELETE_ACCOUNT_TWO_SCREEN: 'delete-account-2nd',
                        DELETE_ACCOUNT_THREE_SCREEN: 'delete-account-3nd',
                        DELETE_ACCOUNT_FOUR_SCREEN: 'delete-account-4nd',
                        REASON_DELETE_SCREEN: 'reason-delete',
                        CONFIRM_DELETE_ACCOUNT_SCREEN: 'confirm-delete-account',
                        CONFIRM_DELETE_SUCCESS_SCREEN: 'delete-account-success',
                        CHANGE_PASSWORD: 'change-password',
                        URL_NOT_FOUND: '*',
                    },
                },
            },
        },
        MY_PROFILE_EDITOR: {
            path: 'dashboard/edit-profile',
            initialRouteName: '/',
        },
        REP_COMMENT: {
            path: 'rep-comment',
            initialRouteName: '/',
        },
        CHANGE_ID: {
            path: 'change-id',
            initialRouteName: '/',
        },
        CREATE_POST: 'create-post',
        CREATE_ARTICLE: 'create-article',
        CREATE_ARTICLE_HASHTAG: 'create-article-hashtag',
        CREATE_ARTICLE_CONFIRM: 'create-article-confirm',
        CREATE_ARTICLE_PREVIEW: 'create-article-preview',
        REP_COMMENT_ARTICLE: 'rep-comment-article',
        AUTH_NAVIGATOR: {
            path: 'authentication',
            initialRouteName: '/',
            screens: {
                LOGIN: 'login',
                INPUT_PASSWORD: {
                    path: 'password',
                    initialRouteName: '/',
                },
                REGISTER: {
                    path: 'register',
                    initialRouteName: '/',
                },
                SELECT_TAG: {
                    path: 'select-tag',
                    initialRouteName: '/',
                },
                TUTORIAL: {
                    path: 'tutorial',
                    initialRouteName: '/',
                },
                FORGOT_PASSWORD: {
                    path: 'forgot-password',
                    initialRouteName: '/',
                },
                CHANGE_PASSWORD: {
                    path: 'change-password',
                    initialRouteName: '/',
                },
                URL_NOT_FOUND: 'not-found',
            },
        },
        EDIT_BIRTHDAY_SCREEN: 'birthday-edit',
        IMAGE_VIEWER_SCREEN: 'image-viewer',
    },
};
