"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: '300',
        color: '#007AFF',
    },
    editContainer: {
        flex: 1,
        marginTop: (0, scale_1.scale)(12),
        position: 'relative',
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    editView: {
        flex: 1,
        marginTop: (0, scale_1.scale)(16),
        display: 'flex',
        flexDirection: 'column-reverse',
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        fontFamily: 'Hiragino Kaku Gothic Pro W3',
        color: '#292A33',
    },
    input: {
        height: 40,
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'gray',
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    buttonContainer: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    backgroundImage: {
        width: '100%',
        height: (0, scale_1.scale)(211),
        backgroundColor: '#F7F7F7',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    title: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    hashtagList: {
        marginTop: (0, scale_1.scale)(8),
        gap: (0, scale_1.scale)(8),
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    hashtagItem: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#007AFF',
    },
    author: {
        marginTop: (0, scale_1.scale)(12),
        flexDirection: 'row',
    },
    avatar: {
        width: (0, scale_1.scale)(42),
        height: (0, scale_1.scale)(42),
        borderRadius: 999,
    },
    userInfo: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(12),
        justifyContent: 'space-between',
    },
    userNickname: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    userHandle: {
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#888888',
    },
    inheritView: {
        marginTop: (0, scale_1.scale)(20),
    },
    inheritUserLabel: {
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#545454',
    },
    articleStatus: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: (0, scale_1.scale)(16),
    },
    articleStatusLeft: {
        flex: 1,
        flexDirection: 'row',
    },
    statusNumber: {
        marginRight: (0, scale_1.scale)(16),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    statusUnit: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#545454',
    },
    statusRow: {
        width: scale_1.width,
        alignSelf: 'center',
        flexDirection: 'row',
        marginTop: (0, scale_1.scale)(8),
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(8),
        alignItems: 'center',
        borderTopWidth: (0, scale_1.scale)(1),
        borderBottomWidth: (0, scale_1.scale)(1),
        borderColor: '#DDDDDD',
    },
    commentButton: {
        marginRight: (0, scale_1.scale)(32),
    },
});
