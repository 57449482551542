"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MESSAGES = void 0;
exports.MESSAGES = {
    CREATED_SUCCESSFULLY: '新規作成しました。',
    UPDATE_SUCCESSFULLY: '更新しました。',
    DELETE_SUCCESSFULLY: '削除しました。',
    ERROR_MESSAGE_DEFAULT: '予期せぬエラーが発生しました。',
    //Change password
    CURRENT_PASSWORD_IS_REQUIRED: '現在のパスワードは、必ず指定してください。',
    CURRENT_PASSWORD_MIN_LENGTH_10: '現在のパスワードは、10文字以上で指定してください。',
    CURRENT_PASSWORD_MUST_NOT_CONTAIN_SPACES: 'パスワードにはスペースを含めることはできません',
    CURRENT_PASSWORD_MUST_UPPER_LOWER_CASE: '現在のパスワードには大文字小文字を含める必要があります。',
    CURRENT_PASSWORD_MUST_CONTAIN_SYMBOLS: '現在のパスワードには記号を含める必要があります。',
    NEW_PASSWORD_IS_REQUIRED: '新しいパスワードは、必ず指定してください。',
    NEW_PASSWORD_MIN_LENGTH_10: '新しいパスワードは、10文字以上で指定してください。',
    NEW_PASSWORD_MUST_NOT_CONTAIN_SPACES: 'パスワードにはスペースを含めることはできません',
    NEW_PASSWORD_MUST_UPPER_LOWER_CASE: '新しいパスワードには大文字小文字を含める必要があります。',
    NEW_PASSWORD_MUST_CONTAIN_SYMBOLS: '新しいパスワードには記号を含める必要があります。',
    NEW_PASSWORD_MUST_BE_DIFFERENT_CURRENT_PASSWORD: '新しいパスワードは現在のパスワードとは異なる必要があります',
    CONFIRM_PASSWOR_IS_REQUIRED: 'パスワードを認証するは、必ず指定してください。',
    PASSWORD_NOT_MATCH: '新しいパスワードの確認と新しいパスワードに同じ値を指定してください。',
    PASSWORD_INVALID: 'パスワードには、有効なパスワードを指定してください'
};
