"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    HOME: 'HOME',
    LOGIN: 'LOGIN',
    MAINTABBAR: 'MAINTABBAR',
    AUTH_NAVIGATOR: 'AUTH_NAVIGATOR',
    HOMENAVIGATOR: 'HOMENAVIGATOR',
    SEARCH_ARTICLE: 'SEARCH_ARTICLE',
    SEARCH_ARTICLE_TAP: 'SEARCH_ARTICLE_TAP',
    SEARCH_HISTORY: 'SEARCH_HISTORY',
    NOTI: 'NOTI',
    INPUT_PASSWORD: 'INPUT_PASSWORD',
    REGISTER: 'REGISTER',
    SETTING_NAVIGATOR: 'SETTING_NAVIGATOR',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    CONFIRM_FORGOT: 'CONFIRM_FORGOT',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    MY_PAGE_SCREEN: 'MY_PAGE_SCREEN',
    CHANGE_ID_SCREEN: 'CHANGE_ID',
    DELETE_ACCOUNT_SCREEN: 'DELETE_ACCOUNT_SCREEN',
    DELETE_ACCOUNT_ONE_SCREEN: 'DELETE_ACCOUNT_ONE_SCREEN',
    DELETE_ACCOUNT_TWO_SCREEN: 'DELETE_ACCOUNT_TWO_SCREEN',
    DELETE_ACCOUNT_THREE_SCREEN: 'DELETE_ACCOUNT_THREE_SCREEN',
    DELETE_ACCOUNT_FOUR_SCREEN: 'DELETE_ACCOUNT_FOUR_SCREEN',
    REASON_DELETE_SCREEN: 'REASON_DELETE_SCREEN',
    CONFIRM_DELETE_ACCOUNT_SCREEN: 'CONFIRM_DELETE_ACCOUNT_SCREEN',
    CONFIRM_DELETE_SUCCESS_SCREEN: 'CONFIRM_DELETE_SUCCESS_SCREEN',
    DISPLAY_INFORMATION_SCREEN: 'DISPLAY_INFORMATION_SCREEN',
    EDIT_PHONE_SCREEN: 'EDIT_PHONE_SCREEN',
    EDIT_PHONE_SUCCESS_SCREEN: 'EDIT_PHONE_SUCCESS_SCREEN',
    EDIT_BIRTHDAY_SCREEN: 'EDIT_BIRTHDAY_SCREEN',
    VERIFY_PHONE_SCREEN: 'VERIFY_PHONE_SCREEN',
    NOTIFY_SETTING: 'NOTIFY_SETTING',
    TIMELINE_NOTIFY_SETTING: 'TIMELINE_NOTIFY_SETTING',
    ARTICLE_NOTIFY_SETTING: 'ARTICLE_NOTIFY_SETTING',
    PERSONAL_HISTORY_NOTIFY_SETTING: 'PERSONAL_HISTORY_NOTIFY_SETTING',
    NOTIFICATION_CHANGE_PASSWORD: 'NOTIFICATION_CHANGE_PASSWORD',
    ACCOUNT_LIMIT_SCREEN: 'ACCOUNT_LIMIT_SCREEN',
    ACCOUNT_BLOCK_OVERVIEW: 'ACCOUNT_BLOCK_OVERVIEW',
    ACCOUNT_MUTE_OVERVIEW: 'ACCOUNT_MUTE_OVERVIEW',
    SELECT_TAG: 'SELECT_TAG',
    TUTORIAL: 'TUTORIAL',
    HOME_ARTICLE_DETAIL: 'HOME_ARTICLE_DETAIL',
    REP_COMMENT: 'REP_COMMENT',
    NOTIFICATION_SCREEN: 'NOTIFICATION_SCREEN',
    DETAIL_NOTI_SCREEN: 'DETAIL_NOTI_SCREEN',
    CREATE_POST: 'CREATE_POST',
    NEW_ARTICLE_LIST: 'NEW_ARTICLE_LIST',
    LIST_ARTICLE_USER: 'LIST_ARTICLE_USER',
    LIST_HASHTAG: 'LIST_HASHTAG',
    FOLLOW_TAG: 'FOLLOW_TAG',
    FOLLOW_SCREEN: 'FOLLOW_SCREEN',
    FOLLOW: 'FOLLOW',
    FOLLOWERS: 'FOLLOWERS',
    HASHTAG: 'HASHTAG',
    HASHTAG_DETAIL: 'HASHTAG_DETAIL',
    DETAIL_STORY: 'DETAIL_STORY',
    LIST_COMMENT_ARTICLE: 'LIST_COMMENT_ARTICLE',
    DETAIL_COMMENT_ARTICLE: 'DETAIL_COMMENT_ARTICLE',
    REP_COMMENT_ARTICLE: 'REP_COMMENT_ARTICLE',
    HISTORY_SEARCH_SCREEN: 'HISTORY_SEARCH_SCREEN',
    HIDDEN_TAB_NAVIGATOR: 'HIDDEN_TAB_NAVIGATOR',
    MY_PROFILE: 'MY_PROFILE',
    USER_PROFILE: 'USER_PROFILE',
    MY_PROFILE_EDITOR: 'MY_PROFILE_EDITOR',
    CREATE_ARTICLE: 'CREATE_ARTICLE',
    CREATE_ARTICLE_HASHTAG: 'CREATE_ARTICLE_HASHTAG',
    CREATE_ARTICLE_CONFIRM: 'CREATE_ARTICLE_CONFIRM',
    CREATE_ARTICLE_PREVIEW: 'CREATE_ARTICLE_PREVIEW',
    POLICY_SCREEN: 'POLICY_SCREEN',
    POLICY_DETAIL_SCREEN: 'POLICY_DETAIL_SCREEN',
    FAQ_SCREEN: 'FAQ_SCREEN',
    COMPANY_INFO: 'COMPANY_INFO',
    BLOCK_USER_SETTING: 'BLOCK_USER_SETTING',
    MUTE_USER_SETTING: 'MUTE_USER_SETTING',
    MIND_MAP_SCREEN: 'MIND_MAP_SCREEN',
    article: 'article',
    history: 'history',
    timeline: 'timeline',
    profile: 'profile',
    IMAGE_VIEWER_SCREEN: "IMAGE_VIEWER_SCREEN",
    URL_NOT_FOUND: "URL_NOT_FOUND",
};
