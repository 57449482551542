"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconUp = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(11), height: (0, scale_1.scale)(7), viewBox: "0 0 11 7", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Path, { d: "M5.49984 0.433857C5.38434 0.433857 5.27684 0.452289 5.17733 0.489153C5.07781 0.526001 4.98321 0.589275 4.89352 0.678975L0.586559 4.98594C0.453862 5.11862 0.38598 5.2854 0.382913 5.48629C0.37983 5.68715 0.447712 5.857 0.586559 5.99583C0.725389 6.13468 0.893704 6.2041 1.0915 6.2041C1.2893 6.2041 1.45762 6.13468 1.59645 5.99583L5.49984 2.09247L9.40322 5.99583C9.53591 6.12853 9.70269 6.19641 9.90357 6.19948C10.1044 6.20256 10.2743 6.13468 10.4131 5.99583C10.552 5.857 10.6214 5.68869 10.6214 5.49089C10.6214 5.29309 10.552 5.12477 10.4131 4.98594L6.10615 0.678975C6.01647 0.589275 5.92186 0.526001 5.82234 0.489153C5.72283 0.452289 5.61533 0.433857 5.49984 0.433857Z", fill: "#1C1B1F" })));
exports.default = IconUp;
