"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    container: {
        flex: 1,
        backgroundColor: "#F7F7F7",
    },
    body: {
        paddingTop: (0, scale_1.scale)(24),
    },
    titleView: {
        width: "100%",
        paddingVertical: (0, scale_1.scale)(12),
        paddingHorizontal: (0, scale_1.scale)(16),
        backgroundColor: "#EEEEEE",
        borderBottomWidth: (0, scale_1.scale)(1),
        borderBottomColor: "#DDDDDD",
    },
    title: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: "#292A33",
    },
});
