"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotiType = exports.Status = exports.Gender = exports.HttpCode = void 0;
var HttpCode;
(function (HttpCode) {
    HttpCode[HttpCode["OK"] = 200] = "OK";
    HttpCode[HttpCode["CREATED"] = 201] = "CREATED";
    HttpCode[HttpCode["ACCEPTED"] = 202] = "ACCEPTED";
    HttpCode[HttpCode["NO_CONTENT"] = 204] = "NO_CONTENT";
    HttpCode[HttpCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HttpCode[HttpCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HttpCode[HttpCode["FORBIDDEN"] = 403] = "FORBIDDEN";
    HttpCode[HttpCode["NOT_FOUND"] = 404] = "NOT_FOUND";
    HttpCode[HttpCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    HttpCode[HttpCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
})(HttpCode || (exports.HttpCode = HttpCode = {}));
var Gender;
(function (Gender) {
    Gender[Gender["Female"] = 1] = "Female";
    Gender[Gender["Male"] = 2] = "Male";
})(Gender || (exports.Gender = Gender = {}));
var Status;
(function (Status) {
    Status[Status["Inactive"] = 0] = "Inactive";
    Status[Status["Active"] = 1] = "Active";
})(Status || (exports.Status = Status = {}));
var NotiType;
(function (NotiType) {
    NotiType["PUSH_NOTI"] = "pushnoti";
    NotiType["BOX_NOTI"] = "boxnoti";
})(NotiType || (exports.NotiType = NotiType = {}));
