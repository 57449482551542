"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("./actions");
const initState = {
    listFollow: [],
    isLoading: false,
    error: null,
};
function listFollowReducer(state = initState, action) {
    switch (action.type) {
        case actions_1.LIST_FOLLOW_START:
            return {
                ...state,
                isLoading: true,
            };
        case actions_1.LIST_FOLLOW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listFollow: action.data,
            };
        case actions_1.LIST_FOLLOW_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.data,
            };
        default:
            return state;
    }
}
exports.default = listFollowReducer;
