"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
function IconPlus({ width = (0, scale_1.scale)(26), height = (0, scale_1.scale)(26), color = '#fff', ...props }) {
    return (React.createElement(react_native_svg_1.default, { width: 26, height: 26, viewBox: "0 0 26 26", fill: "none", ...props },
        React.createElement(react_native_svg_1.Path, { d: "M11.166 14.834H1.999c-.52 0-.955-.176-1.306-.527A1.774 1.774 0 01.166 13c0-.52.176-.954.527-1.306A1.774 1.774 0 012 11.167h9.167V2c0-.52.176-.955.527-1.306A1.774 1.774 0 0113 .167c.52 0 .955.176 1.307.527.351.351.527.787.527 1.306v9.167h9.166c.52 0 .955.176 1.307.527.351.351.527.787.527 1.306 0 .52-.176.955-.527 1.307a1.774 1.774 0 01-1.307.527h-9.166V24c0 .52-.176.955-.527 1.307a1.774 1.774 0 01-1.307.527c-.52 0-.955-.176-1.306-.527A1.774 1.774 0 0111.166 24v-9.166z", fill: color })));
}
exports.default = IconPlus;
