"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#F7F7F7",
    },
    loading: {
        backgroundColor: "transparent",
        paddingVertical: (0, scale_1.scale)(12),
    },
    hashtagView: {
        flex: 1,
        justifyContent: "center",
    },
    textTitle: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
        color: "#292A33",
    },
    textFollow: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: "#1960B0",
    },
    mainButton: {
        marginLeft: (0, scale_1.scale)(24),
    },
    buttonFollow: {
        width: (0, scale_1.scale)(96),
        height: (0, scale_1.scale)(32),
        backgroundColor: "#FEFEFE",
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(4),
        borderColor: "#1960B0",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonInactive: {
        borderWidth: 0,
        backgroundColor: "#DDDDDD",
    },
    buttonPrivate: {
        width: (0, scale_1.scale)(96),
        height: (0, scale_1.scale)(26),
        backgroundColor: "#44A06B",
        borderRadius: (0, scale_1.scale)(3),
        justifyContent: "center",
        alignItems: "center",
    },
    textPrivate: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: "#FEFEFE",
    },
    mainItem: {
        width: "100%",
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(12),
        flexDirection: "row",
        borderBottomColor: "#dddddd",
        borderBottomWidth: (0, scale_1.scale)(1),
    },
});
