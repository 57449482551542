"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconMute = (props) => (React.createElement(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 20 20", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7236_39313", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "20", height: "20" },
        React.createElement(react_native_svg_1.Rect, { width: "20", height: "20", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7236_39313)" },
        React.createElement(react_native_svg_1.Path, { d: "M3.74977 15.7368V14.4869H5.25619V8.26888C5.25619 7.87999 5.30319 7.49366 5.39721 7.10988C5.49122 6.72609 5.63224 6.36029 5.82027 6.01249L6.76577 6.95799C6.67923 7.16985 6.61432 7.38502 6.57104 7.60351C6.52778 7.82198 6.50615 8.04377 6.50615 8.26888V14.4869H12.5703L1.69531 3.6439L2.57348 2.76572L17.442 17.6343L16.5639 18.5124L13.7626 15.7368H3.74977ZM14.7433 12.5445L13.4934 11.2945V8.26888C13.4934 7.30413 13.1523 6.48068 12.4701 5.79853C11.788 5.11636 10.9645 4.77528 9.99975 4.77528C9.58796 4.77528 9.19201 4.84286 8.8119 4.97801C8.43179 5.11316 8.08948 5.31589 7.78498 5.5862L6.88117 4.68236C7.17497 4.42809 7.49628 4.21094 7.8451 4.03092C8.19394 3.8509 8.56494 3.72189 8.9581 3.6439V2.08301H11.0414V3.6439C12.1311 3.8736 13.0206 4.42033 13.7097 5.28411C14.3988 6.14789 14.7433 7.14281 14.7433 8.26888V12.5445ZM9.99833 18.0766C9.58368 18.0766 9.22919 17.9291 8.93485 17.6341C8.64052 17.3391 8.49335 16.9844 8.49335 16.5702H11.5061C11.5061 16.9858 11.3585 17.3407 11.0632 17.635C10.768 17.9294 10.413 18.0766 9.99833 18.0766Z", fill: props?.color || "#D32F2F" }))));
exports.default = IconMute;
