"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectProfile = exports.loginReducer = exports.setErrorMessageAction = exports.setErrorsAction = exports.updateDateOfBirthAction = exports.updatePhoneNumberAction = exports.updateNicknameAction = exports.updateUserInformationAction = exports.updateUserHandleAction = exports.setProfileAction = exports.setLoginStateAction = exports.saveAccessTokenAction = exports.LoginState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.LoginState = {
    IS_UPDATE_INFO: 'IS_UPDATE_INFO',
    IS_LOGGED: 'IS_LOGGED',
    IS_NEED_LOGIN: 'IS_NEED_LOGIN',
    FIRST_SIGN_IN: 'FIRST_SIGN_IN',
    SELECT_PLAN: 'SELECT_PLAN',
    NOTHING: 'NOTHING',
};
const initialState = {
    accessToken: null,
    isLoading: false,
    loginState: exports.LoginState.IS_NEED_LOGIN,
    errors: [],
    errorMessage: '',
    dataProfile: undefined,
    userInformation: {
        phone_number: '',
        date_of_birth: '',
        change_phone_number_flag: 0
    },
};
// create your action
exports.saveAccessTokenAction = (0, toolkit_1.createAction)('saveAccessToken');
exports.setLoginStateAction = (0, toolkit_1.createAction)('setLoginState');
exports.setProfileAction = (0, toolkit_1.createAction)('setProfile');
exports.updateUserHandleAction = (0, toolkit_1.createAction)('updateUserHandle');
exports.updateUserInformationAction = (0, toolkit_1.createAction)('updateUserInformation');
exports.updateNicknameAction = (0, toolkit_1.createAction)('updateNicknameAction');
exports.updatePhoneNumberAction = (0, toolkit_1.createAction)('updatePhoneNumber');
exports.updateDateOfBirthAction = (0, toolkit_1.createAction)('updateDateOfBirth');
exports.setErrorsAction = (0, toolkit_1.createAction)('setErrors');
exports.setErrorMessageAction = (0, toolkit_1.createAction)('setErrorMessage');
const loginSlice = (0, toolkit_1.createSlice)({
    name: 'login',
    initialState,
    reducers: {
        login: (state, _action) => {
            return { ...state, isLoading: true };
        },
    },
    // child action of login
    extraReducers: (builder) => {
        builder.addCase(exports.saveAccessTokenAction, (state, action) => {
            return {
                ...state,
                accessToken: action.payload,
            };
        });
        builder.addCase(exports.setProfileAction, (state, action) => {
            return {
                ...state,
                dataProfile: action.payload ? action.payload : undefined,
            };
        });
        builder.addCase(exports.updateUserHandleAction, (state, action) => {
            return {
                ...state,
                dataProfile: state.dataProfile
                    ? {
                        ...state.dataProfile,
                        user_handle: action.payload,
                    }
                    : undefined,
            };
        });
        builder.addCase(exports.setLoginStateAction, (state, action) => {
            return {
                ...state,
                loginState: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setErrorsAction, (state, action) => {
            return {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setErrorMessageAction, (state, action) => {
            return {
                ...state,
                errorMessage: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.updateUserInformationAction, (state, action) => {
            return {
                ...state,
                userInformation: action.payload,
            };
        });
        builder.addCase(exports.updateNicknameAction, (state, action) => {
            return {
                ...state,
                dataProfile: state.dataProfile
                    ? { ...state.dataProfile, nickname: action.payload }
                    : undefined,
            };
        });
        builder.addCase(exports.updatePhoneNumberAction, (state, action) => {
            return {
                ...state,
                userInformation: { ...state.userInformation, phone_number: action.payload },
            };
        });
        builder.addCase(exports.updateDateOfBirthAction, (state, action) => {
            return {
                ...state,
                userInformation: { ...state.userInformation, date_of_birth: action.payload },
            };
        });
    },
});
exports.loginReducer = loginSlice.reducer;
const selectProfile = (state) => state.loginReducer.dataProfile;
exports.selectProfile = selectProfile;
exports.default = loginSlice;
