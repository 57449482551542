"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    loginForm: {
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
    },
    input: {
        height: 40,
        width: "100%",
        borderRadius: 20,
        backgroundColor: "gray",
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: "white",
        textAlign: "center",
        fontWeight: "600",
    },
    bottomButton: {
        position: "absolute",
        right: (0, scale_1.scale)(16),
        bottom: (0, scale_1.scale)(90),
        height: (0, scale_1.scale)(40),
        width: (0, scale_1.scale)(40),
        alignItems: "center",
    },
    title: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: "#888888",
        paddingBottom: (0, scale_1.scale)(8),
    },
    titleFocused: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: "#007AFF",
        paddingBottom: (0, scale_1.scale)(8),
    },
    tabContainer: {
        flexDirection: "row",
        backgroundColor: "#F7F7F7", // Màu nền của TabBar
        paddingLeft: (0, scale_1.scale)(16)
    },
    tab: {
        paddingVertical: (0, scale_1.scale)(10),
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
    },
    tabLabel: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        paddingHorizontal: (0, scale_1.scale)(16),
        marginBottom: (0, scale_1.scale)(8)
    },
});
