"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        paddingHorizontal: (0, scale_1.scale)(16),
        backgroundColor: '#F7F7F7',
    },
    viewTitle: {
        borderLeftWidth: (0, scale_1.scale)(6),
        borderLeftColor: '#0591E8',
        paddingLeft: (0, scale_1.scale)(8),
        marginBottom: (0, scale_1.scale)(12),
    },
    title: {
        fontSize: (0, scale_1.moderateScale)(16),
        fontWeight: '300',
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
    tagSelect: {
        height: (0, scale_1.scale)(37),
        paddingHorizontal: (0, scale_1.scale)(16),
        borderRadius: 99,
        backgroundColor: '#287BCF',
        justifyContent: 'center',
        marginRight: (0, scale_1.scale)(8),
        marginBottom: (0, scale_1.scale)(8),
    },
    tagNonSelect: {
        height: (0, scale_1.scale)(37),
        paddingHorizontal: (0, scale_1.scale)(16),
        borderRadius: 99,
        backgroundColor: '#FEFEFE',
        justifyContent: 'center',
        marginRight: (0, scale_1.scale)(8),
        marginBottom: (0, scale_1.scale)(8),
        borderWidth: 1,
        borderColor: '#C8C8C8',
    },
    textTagSelect: {
        fontSize: (0, scale_1.moderateScale)(16),
        fontWeight: '300',
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#FEFEFE',
    },
});
