"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    loading: {
        backgroundColor: 'transparent',
        paddingVertical: (0, scale_1.scale)(12),
    },
    emptyText: {
        width: '100%',
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
        textAlign: 'center',
    },
});
