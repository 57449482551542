"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const Words = (props) => (React.createElement(react_native_svg_1.default, { width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15), viewBox: "0 0 15 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_5867_7127", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15) },
        React.createElement(react_native_svg_1.Rect, { width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15), fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_5867_7127)" },
        React.createElement(react_native_svg_1.Path, { d: "M7.50103 13.4375C6.67982 13.4375 5.90793 13.2816 5.18534 12.97C4.46275 12.6583 3.8342 12.2353 3.29969 11.7011C2.76517 11.1668 2.34201 10.5385 2.0302 9.81624C1.7184 9.09398 1.5625 8.32224 1.5625 7.50103C1.5625 6.67982 1.71833 5.90793 2.03 5.18534C2.34167 4.46275 2.76464 3.8342 3.29891 3.29969C3.83319 2.76517 4.46146 2.34201 5.18373 2.0302C5.90599 1.7184 6.67773 1.5625 7.49894 1.5625C8.32015 1.5625 9.09204 1.71833 9.81463 2.03C10.5372 2.34167 11.1658 2.76464 11.7003 3.29891C12.2348 3.83319 12.658 4.46146 12.9698 5.18374C13.2816 5.906 13.4375 6.67773 13.4375 7.49894C13.4375 8.32015 13.2816 9.09204 12.97 9.81463C12.6583 10.5372 12.2353 11.1658 11.7011 11.7003C11.1668 12.2348 10.5385 12.658 9.81624 12.9698C9.09398 13.2816 8.32224 13.4375 7.50103 13.4375ZM6.87498 12.4687V11.25C6.53123 11.25 6.23696 11.1276 5.99217 10.8828C5.74738 10.638 5.62498 10.3437 5.62498 9.99999V9.37499L2.62498 6.37499C2.59373 6.56249 2.56509 6.74999 2.53905 6.93749C2.51301 7.12499 2.49998 7.31249 2.49998 7.49999C2.49998 8.7604 2.91405 9.86457 3.74217 10.8125C4.5703 11.7604 5.61457 12.3125 6.87498 12.4687ZM11.1875 10.875C11.3958 10.6458 11.5833 10.3984 11.75 10.1328C11.9167 9.86717 12.0547 9.59113 12.164 9.30467C12.2734 9.01821 12.3568 8.72394 12.414 8.42186C12.4713 8.11978 12.5 7.81249 12.5 7.49999C12.5 6.47613 12.2171 5.54108 11.6514 4.69483C11.0857 3.84859 10.3269 3.23797 9.37499 2.86297V3.12498C9.37499 3.46873 9.25259 3.76301 9.0078 4.0078C8.76301 4.25259 8.46874 4.37499 8.12499 4.37499H6.87498V5.62499C6.87498 5.80207 6.81509 5.95051 6.6953 6.0703C6.57551 6.19009 6.42707 6.24999 6.24998 6.24999H4.99998V7.49999H8.74999C8.92707 7.49999 9.07551 7.55988 9.1953 7.67967C9.31509 7.79946 9.37499 7.9479 9.37499 8.12499V9.99999H9.99999C10.2708 9.99999 10.5156 10.0807 10.7344 10.2422C10.9531 10.4036 11.1042 10.6146 11.1875 10.875Z", fill: props.color || "#287BCF" }))));
exports.default = Words;
