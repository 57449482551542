"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    headerText: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: '300',
        color: '#007AFF',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    decor: {
        height: '100%',
        width: (0, scale_1.scale)(6),
        backgroundColor: '#287BCF',
    },
    title: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
    description: {
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    hashtagList: {
        width: '100%',
        marginTop: (0, scale_1.scale)(24),
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: (0, scale_1.scale)(8),
        columnGap: (0, scale_1.scale)(4),
    },
    hashtagItem: {
        maxWidth: '100%',
        height: (0, scale_1.scale)(39),
        paddingHorizontal: (0, scale_1.scale)(16),
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: 999,
        borderColor: '#C8C8C8',
        flexDirection: 'row',
        alignItems: 'center',
    },
    hashtagText: {
        marginRight: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(15),
        // lineHeight: moderateScale(22.5),
        color: '#007AFF',
    },
    textInput: {
        width: (0, scale_1.scale)(135),
        height: (0, scale_1.scale)(39),
        outlineWidth: 0,
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    addHashtagBtn: {
        marginTop: (0, scale_1.scale)(24),
        width: '100%',
        height: (0, scale_1.scale)(40),
        // backgroundColor: '#FEFEFE',
    },
    addHashtagLabel: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#1960B0',
    },
    nextBtn: {
        width: '100%',
        height: (0, scale_1.scale)(48),
        borderRadius: (0, scale_1.scale)(4),
    },
    nextBtnLabel: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#FEFEFE',
    },
});
