"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const LockIcon = (props) => (React.createElement(react_native_svg_1.default, { width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15), viewBox: "0 0 15 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7025_3383", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15) },
        React.createElement(react_native_svg_1.Rect, { width: props.height || (0, scale_1.scale)(15), height: props.height || (0, scale_1.scale)(15), fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7025_3383)" },
        React.createElement(react_native_svg_1.Path, { d: "M6.71273 9.21811H8.28723L7.93388 7.24456C8.13019 7.16043 8.28824 7.02842 8.40803 6.84853C8.52782 6.66865 8.58772 6.46893 8.58772 6.24938C8.58772 5.9497 8.48135 5.69349 8.26861 5.48075C8.05587 5.26801 7.79966 5.16164 7.49998 5.16164C7.20031 5.16164 6.9441 5.26801 6.73136 5.48075C6.51862 5.69349 6.41225 5.9497 6.41225 6.24938C6.41225 6.46893 6.47215 6.66865 6.59194 6.84853C6.71173 7.02842 6.86978 7.16043 7.06609 7.24456L6.71273 9.21811ZM7.49998 13.4248C6.14822 13.0562 5.02904 12.2606 4.14242 11.0378C3.25581 9.81507 2.8125 8.44808 2.8125 6.93688V3.34073L7.49998 1.58594L12.1875 3.34073V6.93688C12.1875 8.44808 11.7442 9.81507 10.8575 11.0378C9.97093 12.2606 8.85174 13.0562 7.49998 13.4248Z", fill: props.color || "#575B72" }))));
exports.default = LockIcon;
