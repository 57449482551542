"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginForm: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
    },
    input: {
        fontWeight: react_native_1.Platform.OS === 'ios' ? '300' : 'normal',
        padding: (0, scale_1.scale)(0),
        width: (0, scale_1.scale)(291),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
        outlineWidth: 0,
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    buttonContainer: {
        width: '100%',
        backgroundColor: 'red',
        justifyContent: 'center',
        height: 40,
        marginTop: 15,
        borderRadius: 20,
        paddingHorizontal: 10,
    },
    textContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    text: {
        marginRight: 5,
    },
    hashtag: {
        color: 'blue', // Màu cho hashtag
        marginRight: 5,
    },
    inputText: {
        width: (0, scale_1.scale)(291),
        fontSize: (0, scale_1.moderateScale)(15),
        // lineHeight: moderateScale(23),
        color: '#292A33',
        marginTop: (0, scale_1.scale)(12),
        outlineWidth: 0,
        fontFamily: 'Hiragino Kaku Gothic Pro W3',
    },
    inputTextPlaceholder: {
        width: (0, scale_1.scale)(291),
        fontSize: (0, scale_1.moderateScale)(15),
        // lineHeight: moderateScale(23),
        color: '#C8C8C8',
        marginTop: (0, scale_1.scale)(12),
        outlineWidth: 0,
        fontFamily: 'Hiragino Kaku Gothic Pro W3',
    },
    inputTextContent: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
    },
});
