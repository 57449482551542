"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const ThreeDoc = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(26), height: (0, scale_1.scale)(26), viewBox: "0 0 26 26", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Path, { d: "M14.2992 20.3708C14.2992 20.7294 14.1715 21.035 13.9162 21.2878C13.6609 21.5406 13.3539 21.667 12.9954 21.667C12.6368 21.667 12.3312 21.5393 12.0784 21.284C11.8256 21.0287 11.6992 20.7217 11.6992 20.3631C11.6992 20.0046 11.8269 19.6989 12.0822 19.4462C12.3376 19.1934 12.6445 19.067 13.0031 19.067C13.3616 19.067 13.6673 19.1947 13.9201 19.45C14.1728 19.7053 14.2992 20.0123 14.2992 20.3708ZM14.2992 13.0042C14.2992 13.3627 14.1715 13.6684 13.9162 13.9212C13.6609 14.1739 13.3539 14.3003 12.9954 14.3003C12.6368 14.3003 12.3312 14.1727 12.0784 13.9173C11.8256 13.662 11.6992 13.355 11.6992 12.9965C11.6992 12.6379 11.8269 12.3323 12.0822 12.0795C12.3376 11.8267 12.6445 11.7003 13.0031 11.7003C13.3616 11.7003 13.6673 11.828 13.9201 12.0833C14.1728 12.3387 14.2992 12.6456 14.2992 13.0042ZM14.2992 5.6375C14.2992 5.99605 14.1715 6.30171 13.9162 6.55449C13.6609 6.80727 13.3539 6.93366 12.9954 6.93366C12.6368 6.93366 12.3312 6.80599 12.0784 6.55065C11.8256 6.29532 11.6992 5.98838 11.6992 5.62981C11.6992 5.27127 11.8269 4.9656 12.0822 4.71283C12.3376 4.46005 12.6445 4.33366 13.0031 4.33366C13.3616 4.33366 13.6673 4.46133 13.9201 4.71667C14.1728 4.972 14.2992 5.27894 14.2992 5.6375Z", fill: "#545454" })));
exports.default = ThreeDoc;
