"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IC_DISCONNECT = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(52), height: props.height || (0, scale_1.scale)(52), viewBox: "0 0 53 52", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_8452_14438", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "53", height: "52" },
        React.createElement(react_native_svg_1.Rect, { x: "0.5", width: "52", height: "52", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_8452_14438)" },
        React.createElement(react_native_svg_1.Path, { d: "M14.5834 43.3331C11.2612 43.3331 8.44453 42.1776 6.13341 39.8665C3.8223 37.5554 2.66675 34.7387 2.66675 31.4165C2.66675 28.6359 3.52439 26.1623 5.23966 23.9956C6.95494 21.829 9.16675 20.4387 11.8751 19.8248C11.9834 19.5359 12.0917 19.2561 12.2001 18.9852C12.3084 18.7144 12.4167 18.4165 12.5251 18.0915L5.05008 10.6165C4.65286 10.2192 4.45425 9.71369 4.45425 9.0998C4.45425 8.48592 4.65286 7.98036 5.05008 7.58314C5.4473 7.18592 5.95286 6.9873 6.56675 6.9873C7.18064 6.9873 7.68619 7.18592 8.08342 7.58314L44.9167 44.4165C45.314 44.8137 45.5216 45.3102 45.5397 45.9061C45.5577 46.5019 45.3501 47.0165 44.9167 47.4498C44.5195 47.847 44.023 48.0547 43.4272 48.0727C42.8313 48.0908 42.3167 47.9012 41.8834 47.504L37.6584 43.3331H14.5834ZM14.5834 38.9998H33.3251L15.8834 21.5581C15.8112 21.9554 15.757 22.3345 15.7209 22.6956C15.6848 23.0567 15.6667 23.4359 15.6667 23.8331H14.5834C12.489 23.8331 10.7015 24.5734 9.22092 26.054C7.74036 27.5345 7.00008 29.322 7.00008 31.4165C7.00008 33.5109 7.74036 35.2984 9.22092 36.779C10.7015 38.2595 12.489 38.9998 14.5834 38.9998ZM47.3001 40.6248L44.1584 37.5915C44.7723 37.0859 45.2327 36.4991 45.5397 35.8311C45.8466 35.163 46.0001 34.4137 46.0001 33.5831C46.0001 32.0665 45.4765 30.7845 44.4292 29.7373C43.382 28.6901 42.1001 28.1665 40.5834 28.1665H37.3334V23.8331C37.3334 20.8359 36.2772 18.2811 34.1647 16.1686C32.0522 14.0561 29.4973 12.9998 26.5001 12.9998C25.5251 12.9998 24.5862 13.1172 23.6834 13.3519C22.7806 13.5866 21.914 13.9567 21.0834 14.4623L17.9417 11.3206C19.2056 10.454 20.5508 9.79494 21.9772 9.34355C23.4036 8.89217 24.9112 8.66647 26.5001 8.66647C30.7251 8.66647 34.3091 10.138 37.2522 13.0811C40.1952 16.0241 41.6667 19.6081 41.6667 23.8331C44.1584 24.122 46.2258 25.1963 47.8688 27.0561C49.5119 28.9158 50.3334 31.0915 50.3334 33.5831C50.3334 34.9915 50.0626 36.3005 49.5209 37.5102C48.9792 38.7199 48.239 39.7581 47.3001 40.6248Z", fill: "#C8C8C8" }))));
exports.default = IC_DISCONNECT;
