"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#F7F7F7",
    },
    titleList: {
        width: "100%",
        height: (0, scale_1.scale)(42),
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(12),
        backgroundColor: "#dddddd",
        marginTop: (0, scale_1.scale)(24),
    },
    boxList: {
        width: "100%",
        height: (0, scale_1.scale)(55),
        padding: (0, scale_1.scale)(16),
        justifyContent: "space-between",
        borderBottomColor: "#ddd",
        borderBottomWidth: (0, scale_1.scale)(1),
        flexDirection: "row",
    },
    err: {
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
        width: "100%",
        textAlign: "left",
    },
});
