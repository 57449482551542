"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("./actions");
const initState = {
    user: null,
    isLoading: false,
    error: null,
};
function loginFirebaseReducer(state = initState, action) {
    switch (action.type) {
        case actions_1.LOGIN_FIREBASE_START:
            return {
                ...state,
                isLoading: true,
            };
        case actions_1.LOGIN_FIREBASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.data,
            };
        case actions_1.LOGIN_FIREBASE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.data,
            };
        default:
            return state;
    }
}
exports.default = loginFirebaseReducer;
