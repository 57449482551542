"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IC_Err_Calender = (props) => (React.createElement(react_native_svg_1.default, { width: 21, height: 21, viewBox: "0 0 21 21", fill: "none", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7934_1268", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 21, height: 21 },
        React.createElement(react_native_svg_1.Rect, { width: 21, height: 21, fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7934_1268)" },
        React.createElement(react_native_svg_1.Path, { d: "M7.349 14.5711L10.499 11.4211L13.649 14.5711L14.5711 13.649L11.4211 10.499L14.5711 7.349L13.649 6.42691L10.499 9.57691L7.349 6.42691L6.4269 7.349L9.57691 10.499L6.4269 13.649L7.349 14.5711ZM10.5005 18.8115C9.35078 18.8115 8.27012 18.5933 7.25851 18.157C6.24687 17.7206 5.3669 17.1285 4.61859 16.3805C3.87026 15.6325 3.27783 14.7529 2.84131 13.7418C2.40479 12.7306 2.18652 11.6502 2.18652 10.5005C2.18652 9.35078 2.40469 8.27012 2.84102 7.25851C3.27736 6.24687 3.86951 5.3669 4.61749 4.61859C5.36549 3.87026 6.24507 3.27783 7.25625 2.84131C8.26742 2.40479 9.34785 2.18652 10.4975 2.18652C11.6472 2.18652 12.7279 2.40469 13.7395 2.84102C14.7511 3.27736 15.6311 3.86951 16.3794 4.61749C17.1277 5.36549 17.7202 6.24507 18.1567 7.25625C18.5932 8.26742 18.8115 9.34785 18.8115 10.4975C18.8115 11.6472 18.5933 12.7279 18.157 13.7395C17.7206 14.7511 17.1285 15.6311 16.3805 16.3794C15.6325 17.1277 14.7529 17.7202 13.7418 18.1567C12.7306 18.5932 11.6502 18.8115 10.5005 18.8115Z", fill: "#FEFEFE" }))));
exports.default = IC_Err_Calender;
