"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconEyesOpen = (props) => (React.createElement(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 22 23", fill: "none" },
    React.createElement(react_native_svg_1.Path, { d: "M11.004 14.9368C12.0869 14.9368 13.0065 14.5578 13.7628 13.7998C14.519 13.0417 14.8971 12.1212 14.8971 11.0383C14.8971 9.95539 14.5181 9.03581 13.7601 8.27956C13.002 7.52331 12.0815 7.14518 10.9986 7.14518C9.91568 7.14518 8.99609 7.52421 8.23984 8.28226C7.48359 9.0403 7.10547 9.96079 7.10547 11.0437C7.10547 12.1266 7.4845 13.0462 8.24255 13.8025C9.00059 14.5587 9.92107 14.9368 11.004 14.9368ZM10.9959 13.6077C10.2815 13.6077 9.67596 13.3576 9.17943 12.8575C8.6829 12.3574 8.43464 11.7501 8.43464 11.0356C8.43464 10.3212 8.68469 9.71567 9.18481 9.21914C9.68495 8.72261 10.2922 8.47435 11.0067 8.47435C11.7212 8.47435 12.3267 8.72441 12.8232 9.22453C13.3197 9.72466 13.568 10.332 13.568 11.0464C13.568 11.7609 13.3179 12.3664 12.8178 12.8629C12.3177 13.3594 11.7104 13.6077 10.9959 13.6077ZM11.0013 17.916C8.77075 17.916 6.75408 17.282 4.9513 16.0139C3.14852 14.7459 1.80408 13.0882 0.917969 11.041C1.80408 8.99379 3.14852 7.33615 4.9513 6.0681C6.75408 4.80004 8.77075 4.16602 11.0013 4.16602C13.2319 4.16602 15.2485 4.80004 17.0513 6.0681C18.8541 7.33615 20.1985 8.99379 21.0846 11.041C20.1985 13.0882 18.8541 14.7459 17.0513 16.0139C15.2485 17.282 13.2319 17.916 11.0013 17.916ZM10.9974 16.541C12.8486 16.541 14.5496 16.0407 16.1003 15.04C17.651 14.0393 18.8312 12.7063 19.6409 11.041C18.8312 9.37574 17.6522 8.04275 16.1041 7.04206C14.556 6.04136 12.8564 5.54102 11.0052 5.54102C9.15398 5.54102 7.45304 6.04136 5.90234 7.04206C4.35165 8.04275 3.1638 9.37574 2.3388 11.041C3.1638 12.7063 4.35036 14.0393 5.89847 15.04C7.4466 16.0407 9.14625 16.541 10.9974 16.541Z", fill: props?.color || "#C8C8C8" })));
exports.default = IconEyesOpen;
