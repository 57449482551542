"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.images = void 0;
exports.images = {
    BackgroundImage: require('./background_image.png'),
    BackgroundButton: require('./button_background.png'),
    PrimaryButton: require('./primary_button.png'),
    LogoTextApp: require('./logo_text_app.png'),
    IconHome: require('./iconHome.png'),
    IconNoti: require('./iconNoti.png'),
    IconSearchArticle: require('./iconSearchArticle.png'),
    IconSearchHistory: require('./iconSearchHis.png'),
    LogoTitle: require('./logoTitle.png'),
    AvatarDefaul: require('./avatarDefaul.png'),
    NoImages: require('./noImages.png'),
    ButtonAdd: require('./buttonAdd.png'),
    UploadImg: require('./uploadImg.png'),
    LoadingHome: require('./LoadingHome.png'),
    IconCheck: require('./iconCheck.png'),
    Image1: require('./image1.png'),
    Image2: require('./image2.png'),
    Image3: require('./image3.png'),
    Image4: require('./image4.png'),
    NotificationGroup: require('./NotificationGroup.png'),
    ArticleInh: require('./articleInh.png'),
    FollowUse: require('./followUse.png'),
    LikeTweet: require('./LikeTweet.png'),
    postAritcle: require('./postArticle.png'),
    ReTweet: require('./reTweet.png'),
    Tweet: require('./tweet.png'),
    PolicyLogo: require('./policy-logo.svg'),
    HomeFocus: require('./homeFocus.png'),
    Tutorial: require('./tutorial.png'),
    NotiFocus: require('./notiFocus.png'),
    HistoryFocus: require('./historyFocus.png'),
    SearchFocus: require('./searchFocus.png'),
    warningImage: require('./warning.png'),
    Check: require('./check.png'),
    BackGroundPng: require('./backgroundPng.png')
};
