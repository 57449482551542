"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: '#F7F7F7',
        justifyContent: 'space-between',
    },
    body: {
        flex: 1,
    },
    backgroundImage: {
        width: '100%',
        height: (0, scale_1.scale)(125),
        backgroundColor: '#F7F7F7',
    },
    userInfo: {
        width: '100%',
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    avatar: {
        width: (0, scale_1.scale)(60),
        height: (0, scale_1.scale)(60),
        borderWidth: (0, scale_1.scale)(4),
        borderRadius: 999,
        borderColor: '#F7F7F7',
        marginTop: -(0, scale_1.scale)(16),
    },
    fullName: {
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
    username: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#7C7C7C',
    },
    editProfileButton: {
        flex: 1,
        height: (0, scale_1.scale)(32),
        marginRight: (0, scale_1.scale)(12),
    },
    settingButton: {
        width: (0, scale_1.scale)(32),
        height: (0, scale_1.scale)(32),
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(3),
        borderColor: '#AAAAAA',
        justifyContent: 'center',
        alignItems: 'center',
    },
    followAnalyst: {
        width: '100%',
        marginTop: (0, scale_1.scale)(20),
        borderTopWidth: (0, scale_1.scale)(1),
        borderBottomWidth: (0, scale_1.scale)(1),
        borderColor: '#DDDDDD',
    },
    followItem: {
        flex: 1,
        padding: (0, scale_1.scale)(8),
        justifyContent: 'center',
        alignItems: 'center',
    },
    followItemLabel: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#292A33',
    },
    followItemValue: {
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    introduce: {
        marginTop: (0, scale_1.scale)(20),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    introduceHashTag: {
        color: '#007AFF',
    },
    introduceVideo: {
        width: '100%',
        marginTop: (0, scale_1.scale)(12),
        height: (0, scale_1.scale)(192.94),
        backgroundColor: 'black',
        borderRadius: (0, scale_1.scale)(8),
        overflow: 'hidden',
    },
    locationText: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#545454',
    },
    date: {
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#888888',
    },
});
