"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    body: {
        flex: 1,
    },
    tabBar: {
        position: 'relative',
        width: '100%',
        height: (0, scale_1.scale)(33),
        marginTop: (0, scale_1.scale)(24),
        marginBottom: (0, scale_1.scale)(12),
        flexDirection: 'row',
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    tabBarItem: {
        flex: 1,
        height: '100%',
        zIndex: 1,
    },
    tabBarItemLabel: {
        flex: 1,
        width: '100%',
        textAlign: 'center',
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#888888',
    },
    tabBarItemActive: {
        width: '100%',
        height: (0, scale_1.scale)(4),
        borderRadius: 999,
        backgroundColor: '#007AFF',
    },
    tabBarDecorLine: {
        position: 'absolute',
        height: (0, scale_1.scale)(4),
        borderRadius: 999,
        backgroundColor: '#DDDDDD',
        bottom: 0,
        left: (0, scale_1.scale)(16),
        right: (0, scale_1.scale)(16),
        zIndex: 0,
    },
    listHeader: {
        width: '100%',
        marginBottom: (0, scale_1.scale)(12),
        paddingHorizontal: (0, scale_1.scale)(16),
        alignItems: 'flex-start',
    },
    listHeaderLabel: {
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    listHeaderButton: {
        marginTop: (0, scale_1.scale)(4),
    },
    listHeaderButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#007AFF',
    },
    accountItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: (0, scale_1.scale)(16),
        paddingVertical: (0, scale_1.scale)(12),
        borderBottomWidth: (0, scale_1.scale)(1),
        borderBottomColor: '#DDDDDD',
    },
    accountItemAvatar: {
        width: (0, scale_1.scale)(42),
        height: (0, scale_1.scale)(42),
        borderRadius: 999,
    },
    accountItemInfo: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(8),
        marginRight: (0, scale_1.scale)(20),
    },
    accountItemName: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    accountItemTagName: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(10),
        lineHeight: (0, scale_1.moderateScale)(17),
        color: '#545454',
    },
    accountItemButton: {
        width: (0, scale_1.scale)(96),
        height: (0, scale_1.scale)(30),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(3),
    },
    accountItemButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#545454',
    },
    emptyText: {
        marginTop: (0, scale_1.scale)(20),
        width: '100%',
        textAlign: 'center',
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
    loading: {
        backgroundColor: 'transparent',
        paddingVertical: (0, scale_1.scale)(12),
    },
});
