"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const TempScreen = (props) => {
    const { uri } = props;
    const [originalSize, setOriginalSize] = (0, react_1.useState)({ width: 0, height: 0 });
    (0, react_1.useEffect)(() => {
        let heightView = (0, scale_1.scale)(120);
        if (react_native_1.Platform.OS == 'web') {
            react_native_1.Image.getSize(uri, (width, height) => {
                let widthImage = (width / height) * heightView;
                setOriginalSize({
                    width: widthImage < (0, scale_1.scale)(120) ? (0, scale_1.scale)(120) : widthImage,
                    height: heightView,
                });
            }, (_error) => { });
        }
        else {
            const getImageSize = async () => {
                const resolvedImageSource = react_native_1.Image.resolveAssetSource(uri);
                setOriginalSize({
                    width: heightView,
                    height: (resolvedImageSource.height / resolvedImageSource.width) * heightView,
                });
            };
            getImageSize();
            // Lấy kích thước ban đầu của ảnh
        }
    }, [uri]);
    return (react_1.default.createElement(react_native_1.Image, { source: uri, style: {
            width: originalSize?.width,
            height: originalSize?.height,
            borderRadius: (0, scale_1.scale)(8),
            overflow: 'hidden',
            resizeMode: 'contain',
            marginRight: (0, scale_1.scale)(2)
        }, resizeMode: 'cover' }));
};
exports.default = TempScreen;
