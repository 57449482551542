"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    loginForm: {
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
    },
    input: {
        height: 40,
        width: "100%",
        borderRadius: 20,
        backgroundColor: "gray",
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: "white",
        textAlign: "center",
        fontWeight: "600",
    },
    buttonContainer: {
        width: "100%",
        backgroundColor: "red",
        justifyContent: "center",
        height: 40,
        marginTop: 15,
        borderRadius: 20,
        paddingHorizontal: 10,
    },
    loading: {
        backgroundColor: "transparent",
        paddingVertical: (0, scale_1.scale)(12),
    },
});
