"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconMindMapDown = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(30), height: props.width || (0, scale_1.scale)(30), viewBox: "0 0 30 30", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_13187_16708", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "30", height: "30" },
        React.createElement(react_native_svg_1.Rect, { width: "30", height: "30", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_13187_16745)" },
        React.createElement(react_native_svg_1.Path, { d: "M6.56253 25.6249C6.29691 25.6249 6.07425 25.5351 5.89456 25.3553C5.71487 25.1755 5.62503 24.9528 5.62503 24.687C5.62503 24.4213 5.71487 24.1987 5.89456 24.0192C6.07425 23.8397 6.29691 23.75 6.56253 23.75H23.4375C23.7031 23.75 23.9258 23.8399 24.1054 24.0196C24.2851 24.1994 24.375 24.4221 24.375 24.6879C24.375 24.9536 24.2851 25.1762 24.1054 25.3557C23.9258 25.5352 23.7031 25.6249 23.4375 25.6249H6.56253ZM14.9984 20.3461C14.8488 20.3461 14.7091 20.3221 14.5793 20.274C14.4495 20.2259 14.3261 20.1434 14.2092 20.0264L9.84137 15.6586C9.66829 15.4855 9.57975 15.268 9.57575 15.006C9.57173 14.744 9.66027 14.5224 9.84137 14.3413C10.0225 14.1602 10.242 14.0697 10.5 14.0697C10.758 14.0697 10.9775 14.1602 11.1586 14.3413L14.0625 17.2452V5.3125C14.0625 5.04688 14.1524 4.82422 14.3322 4.64453C14.5119 4.46484 14.7347 4.375 15.0004 4.375C15.2662 4.375 15.4888 4.46484 15.6683 4.64453C15.8477 4.82422 15.9375 5.04688 15.9375 5.3125V17.2452L18.8414 14.3413C19.0144 14.1683 19.232 14.0797 19.494 14.0757C19.756 14.0717 19.9775 14.1602 20.1586 14.3413C20.3397 14.5224 20.4303 14.742 20.4303 15C20.4303 15.258 20.3397 15.4775 20.1586 15.6586L15.7908 20.0264C15.6739 20.1434 15.5499 20.2259 15.4191 20.274C15.2882 20.3221 15.148 20.3461 14.9984 20.3461Z", fill: "#292A33" }))));
exports.default = IconMindMapDown;
