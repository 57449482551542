"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockFAQ = exports.mockAccounts = exports.dataComment = exports.dataHashtag = exports.notifications = exports.follow = exports.followers = exports.dataDetail = exports.dataLike = exports.data1 = exports.dataRetweet = exports.data = exports.Violation = exports.Notification = exports.dataTutorial = exports.dataListTag = void 0;
const images_1 = require("../assets/images");
exports.dataListTag = [
    { id: 1, tagName: '#米国株' },
    { id: 2, tagName: '#タグ' },
    { id: 3, tagName: '#タグ' },
    { id: 4, tagName: '#タグ' },
    { id: 5, tagName: '#タグ' },
    { id: 6, tagName: '#タグ' },
    { id: 7, tagName: '#タグ' },
    { id: 8, tagName: '#タグ' },
    { id: 9, tagName: '#タグ' },
    { id: 10, tagName: '#タグ' },
    { id: 11, tagName: '#タグ' },
    { id: 12, tagName: '#タグ' },
    { id: 13, tagName: '#タグ' },
    { id: 14, tagName: '#タグ' },
    { id: 15, tagName: '#タグ' },
    { id: 16, tagName: '#タグ' },
    { id: 17, tagName: '#タグ' },
    { id: 18, tagName: '#タグ' },
    { id: 19, tagName: '#タグ' },
    { id: 20, tagName: '#タグ' },
    { id: 21, tagName: '#タグ' },
    { id: 22, tagName: '#タグ' },
    { id: 23, tagName: '#タグ' },
    { id: 24, tagName: '#タグ' },
    { id: 25, tagName: '#タグ' },
    { id: 26, tagName: '#タグ' },
    { id: 27, tagName: '#タグ' },
    { id: 28, tagName: '#タグ' },
    { id: 29, tagName: '#タグ' },
    { id: 30, tagName: '#タグ' },
    { id: 31, tagName: '#タグ' },
    { id: 32, tagName: '#タグ' },
    { id: 33, tagName: '#タグ' },
    { id: 34, tagName: '#タグ' },
    { id: 35, tagName: '#タグ' },
    { id: 36, tagName: '#タグ' },
    { id: 37, tagName: '#タグ' },
    { id: 38, tagName: '#タグ' },
];
exports.dataTutorial = [
    { id: 1, imageUri: null, text: 'チュートリアルの詳細文が入ります' },
    {
        id: 2,
        imageUri: null,
        text: 'チュートリアルの詳細文が入りますチュートリアルの詳細文が入ります',
    },
    {
        id: 3,
        imageUri: null,
        text: 'チュートリアルの詳細文が入りますチュートリアルの詳細文が入りますチュートリアルの詳細文が入ります',
    },
    {
        id: 4,
        imageUri: null,
        text: 'チュートリアルの詳細文が入りますチュートリアルの詳細文が入ります',
    },
    {
        id: 5,
        imageUri: null,
        text: 'チュートリアルの詳細文が入りますチュートリアルの詳細文が入りますチュートリアルの詳細文が入ります',
    },
];
exports.Notification = [
    { id: 1, tagName: 'ポルノや露骨な性的コンテンツ' },
    { id: 2, tagName: '児童虐待' },
    { id: 3, tagName: '悪意のある表現や露骨な暴力描写' },
    { id: 4, tagName: '嫌がらせ、いじめ' },
    { id: 5, tagName: '権利の侵害' },
    { id: 6, tagName: 'その他の法的問題' },
    { id: 7, tagName: 'スパムまたは誤解を招く内容' },
    { id: 8, tagName: '上記以外の問題' },
];
exports.Violation = [
    { id: 0, name: '未選択' },
    { id: 1, name: '著作権の問題' },
    { id: 2, name: 'プライバシーの問題' },
    { id: 3, name: '商標権侵害' },
    { id: 4, name: '名誉毀損' },
    { id: 5, name: '偽造品' },
];
exports.data = [
    {
        id: 1,
        name: '田中太郎',
        userId: 1,
        imageUser: null,
        image: [images_1.images.Image1],
        time: '1分以内',
        tagName: '@taro_tanaka_ts',
        content: 'Aperiam ratione rerum aut et accusantium maxime quia. Iste ab et occaecati tempora aut et. Voluptas laborum qui accusamus ut architecto. Et aut commodi magni suscipit. Quos a molestiae tempore harum incidunt quibusdam. Ad laudantium ab rerum corporis voluptatibus. Nam similique nihil atque eaque facilis voluptate et. Sequi similique maiores et quia quaerat aspernatur. Et tempore facere qui sint temporibus ullam. Quod asperiores adipisci facilis. Neque vitae quae aperiam sequi non dolore corporis.',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 0,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 1,
    },
    {
        id: 2,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: [images_1.images.Image2],
        userId: 2,
        time: '1分前',
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 2,
        retweetUsers: {
            imageUser: null,
            name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        },
    },
    {
        id: 3,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: [images_1.images.Image1, images_1.images.Image2, images_1.images.Image3, images_1.images.Image4],
        userId: 11,
        time: '1分前',
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 2,
        type: 1,
    },
    {
        id: 4,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: null,
        time: '1分前',
        userId: 4,
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 2,
    },
];
exports.dataRetweet = [
    {
        id: 1,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: null,
        time: '1分前',
        userId: 4,
        tagName: '@taro_tanaka_ts',
        content: 'Aperiam ratione rerum aut et accusantium maxime quia. Iste ab et occaecati tempora aut et. Voluptas laborum qui accusamus ut architecto. Et aut commodi magni suscipit. Quos a molestiae tempore harum incidunt quibusdam. Ad laudantium ab rerum corporis voluptatibus. Nam similique nihil atque eaque facilis voluptate et. Sequi similique maiores et quia quaerat aspernatur. Et tempore facere qui sint temporibus ullam. Quod asperiores adipisci facilis. Neque vitae quae aperiam sequi non dolore corporis.',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 3,
        quoteTweetPath: {
            id: 2,
            name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
            imageUser: null,
            image: [],
            userId: 2,
            time: '1分前',
            tagName: '@taro_tanaka_ts',
            content: 'ダミーテキスト',
            hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
            numberComment: 0,
            numberLikes: 2,
            timenow: '2022/01/01 0:00',
            status: 1,
            type: 2,
        },
    },
    {
        id: 2,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: [images_1.images.Image1],
        time: '1分前',
        userId: 4,
        tagName: '@taro_tanaka_ts',
        content: null,
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 3,
        quoteTweetPath: {
            id: 2,
            name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
            imageUser: null,
            image: [],
            userId: 2,
            time: '1分前',
            tagName: '@taro_tanaka_ts',
            content: 'Aperiam ratione rerum aut et accusantium maxime quia. Iste ab et occaecati tempora aut et. Voluptas laborum qui accusamus ut architecto. Et aut commodi magni suscipit. Quos a molestiae tempore harum incidunt quibusdam. Ad laudantium ab rerum corporis voluptatibus. Nam similique nihil atque eaque facilis voluptate et. Sequi similique maiores et quia quaerat aspernatur. Et tempore facere qui sint temporibus ullam. Quod asperiores adipisci facilis. Neque vitae quae aperiam sequi non dolore corporis.',
            hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
            numberComment: 0,
            numberLikes: 2,
            timenow: '2022/01/01 0:00',
            status: 1,
            type: 2,
        },
    },
    {
        id: 3,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: [images_1.images.Image1, images_1.images.Image2, images_1.images.Image3, images_1.images.Image4],
        time: '1分前',
        userId: 4,
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 3,
        quoteTweetPath: {
            id: 2,
            name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
            imageUser: null,
            image: [images_1.images.Image1],
            userId: 2,
            time: '1分前',
            tagName: '@taro_tanaka_ts',
            content: 'ダミーテキスト',
            hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
            numberComment: 0,
            numberLikes: 2,
            timenow: '2022/01/01 0:00',
            status: 1,
            type: 2,
        },
    },
    {
        id: 4,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        imageUser: null,
        image: null,
        time: '1分前',
        userId: 4,
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキストダミーテキストダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 2,
        timenow: '2022/01/01 0:00',
        status: 1,
        type: 3,
        quoteTweetPath: {
            id: 2,
            name: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
            imageUser: null,
            image: [images_1.images.Image1, images_1.images.Image2, images_1.images.Image3, images_1.images.Image4],
            userId: 2,
            time: '1分前',
            tagName: '@taro_tanaka_ts',
            content: 'ダミーテキストダミーテキストダミーテキスト',
            hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
            numberComment: 0,
            numberLikes: 2,
            timenow: '2022/01/01 0:00',
            status: 1,
            type: 2,
        },
    },
];
exports.data1 = [
    {
        id: 1,
        imagesArticles: null,
        content: '記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル',
        hashtag: [
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
        ],
        coin: 99,
        avatarImage: null,
        nameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagnameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        numberComment: 999,
        numberLikes: 9,
    },
    {
        id: 2,
        imagesArticles: null,
        content: '記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル',
        hashtag: [
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
            '#ハッシュタグ',
        ],
        coin: 99,
        avatarImage: null,
        nameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagnameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
        numberComment: 999,
        numberLikes: 9,
    },
];
exports.dataLike = [
    {
        id: 1,
        imageUser: null,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        flow: false,
    },
    {
        id: 2,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: true,
    },
    {
        id: 3,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: true,
    },
    {
        id: 4,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: true,
    },
    {
        id: 5,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: true,
    },
    {
        id: 6,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: false,
    },
    {
        id: 7,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: false,
    },
    {
        id: 8,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: false,
    },
    {
        id: 9,
        imageUser: null,
        name: '田中太郎田中太郎田中太郎田中太郎田中太郎',
        tagName: '@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts@taro_tanaka_ts',
        flow: false,
    },
];
exports.dataDetail = {
    id: 1,
    name: '田中太郎',
    image: null,
    time: '20XX/01/01 0:00',
    tagName: '@taro_tanaka_ts',
    content: 'ダミーテキストダミーテキストダミーテキストダミーテキスト',
    hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
    numberComment: 0,
    numberLikes: 0,
    timenow: '2022/01/01 0:00',
    status: 2,
    edit: false,
};
exports.followers = [
    { id: 1, hashTag: '#ハッシュタグ', isFollow: false, isPrivate: false },
    { id: 2, hashTag: '#ハッシュグ', isFollow: true, isPrivate: true },
    { id: 3, hashTag: '#ハッシュタグ', isFollow: true, isPrivate: false },
    { id: 4, hashTag: '#ハッシタグ', isFollow: true, isPrivate: true },
    { id: 5, hashTag: '#ハッシュタグ', isFollow: true, isPrivate: true },
    { id: 6, hashTag: '#ハッュタグ', isFollow: false, isPrivate: true },
    {
        id: 7,
        hashTag: '##ハッシュタグハッシュタグハッシュタグ',
        isFollow: true,
        isPrivate: true,
    },
    { id: 8, hashTag: '#ハッシュタグ', isFollow: true, isPrivate: true },
    { id: 9, hashTag: '#ハシュタグ', isFollow: true, isPrivate: true },
    {
        id: 10,
        hashTag: '##ハッシュタグハッシュタグハッシュタグ',
        isFollow: true,
        isPrivate: true,
    },
    { id: 11, hashTag: '#ハッシュタグ', isFollow: true, isPrivate: true },
    { id: 12, hashTag: '#ハシュタグ', isFollow: true, isPrivate: true },
];
exports.follow = [
    {
        id: 1,
        userName: '田中太郎',
        userID: '@taro_tanaka_ts',
        description: 'ります自己紹介が入ります',
        isFollow: false,
    },
    {
        id: 2,
        userName: '田太郎',
        userID: '@taro_tanaka_ts',
        description: '',
        isFollow: false,
    },
    {
        id: 3,
        userName: '田中太郎',
        userID: '@taro_tanaka_ts',
        description: '自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります',
        isFollow: false,
    },
    {
        id: 4,
        userName: '田中郎',
        userID: '@hanako_tanakahanako_tanakahanako_tanaka',
        description: '自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります自己紹介が入ります',
        isFollow: false,
    },
    {
        id: 5,
        userName: '田中太郎',
        userID: '@taro_tanaka_ts',
        description: 'ります自己紹介が入ります',
        isFollow: false,
    },
    {
        id: 6,
        userName: '田太郎',
        userID: '@taro_tanaka_ts',
        description: 'sdf',
        isFollow: false,
    },
    {
        id: 7,
        userName: '田中太郎',
        userID: '@taro_tanaka_ts',
        description: 'ります自己紹介が入ります',
        isFollow: false,
    },
    {
        id: 8,
        userName: '田太郎',
        userID: '@taro_tanaka_ts',
        description: '',
        isFollow: false,
    },
];
exports.notifications = [
    {
        id: 1,
        title: 'タイムライン',
        message: '通知内容が入ります通知内容が入ります通知内容が入ります',
        time: '1時間',
    },
    {
        id: 2,
        title: 'タイムライン',
        message: '通知内容が入ります',
        time: '1時間前',
    },
    {
        id: 3,
        title: 'タイムライン',
        message: '通知内容が入ります',
        time: '1時前',
    },
    {
        id: 4,
        title: 'タイムライン',
        message: '入ります通知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入り知内容が入ります通知内容が入りま',
        time: '1時間前',
    },
    {
        id: 5,
        title: 'タイムライン',
        message: '通知内容が入ります',
        time: '1時間',
    },
];
exports.dataHashtag = [
    {
        hashtag: { id: 1, tagName: '#米国株', post_count: 0 },
        articles: [
            {
                id: 1,
                imagesArticles: null,
                content: '記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル',
                hashtag: [
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                ],
                coin: 99,
                avatarImage: null,
                nameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
                tagnameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
                numberComment: 999,
                numberLikes: 9,
            },
        ],
    },
    {
        hashtag: { id: 2, tagName: '#ハッシュタグ', post_count: 999 },
        articles: [
            {
                id: 2,
                imagesArticles: null,
                content: '記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル記事タイトル',
                hashtag: [
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                    '#ハッシュタグ',
                ],
                coin: 99,
                avatarImage: null,
                nameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
                tagnameUser: '田中太郎田中太郎田中太郎田中太郎田中太郎田中太郎',
                numberComment: 999,
                numberLikes: 9,
            },
        ],
    },
];
exports.dataComment = [
    {
        id: 1,
        name: '田中太郎',
        userId: 1,
        imageUser: null,
        image: [],
        time: '1分以内',
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 0,
        type: 1,
    },
    {
        id: 2,
        name: '田中花子田中花子田中花子田中花子',
        userId: 2,
        imageUser: null,
        image: [],
        time: '1分以内',
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 0,
        type: 1,
    },
    {
        id: 3,
        name: '田中太郎',
        userId: 3,
        imageUser: null,
        image: [],
        time: '1分以内',
        tagName: '@taro_tanaka_ts',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 0,
        numberLikes: 0,
        type: 1,
    },
    {
        id: 4,
        name: '田中花子田中花子田中花子田中花子',
        userId: 4,
        imageUser: null,
        image: [],
        time: '1分以内',
        tagName: '@hanako_tanakahanako_tanakahanako_tanakahanako_tanaka',
        content: 'ダミーテキスト',
        hashtag: ['#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ', '#ハッシュタグ'],
        numberComment: 99,
        numberLikes: 0,
        type: 1,
    },
];
exports.mockAccounts = [
    {
        id: 1,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        isBlock: false,
    },
    {
        id: 2,
        name: '田中花子田中花子田中花子田中花子',
        tagName: '@hanako_tanakahanako_tanakahanako_tanaka',
        isBlock: true,
    },
    {
        id: 3,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        isBlock: true,
    },
    {
        id: 4,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        isBlock: false,
    },
    {
        id: 5,
        name: '田中花子',
        tagName: '@hanako_tanaka',
        isBlock: true,
    },
    {
        id: 6,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        isBlock: true,
    },
    {
        id: 7,
        name: '田中太郎',
        tagName: '@taro_tanaka_ts',
        isBlock: false,
    },
];
exports.mockFAQ = [
    {
        title: '質問が入ります',
        content: '回答が入ります',
    },
    {
        title: '質問が入ります',
        content: '回答が入ります',
    },
    {
        title: '質問が入ります質問が入ります質問が入ります質問が入ります',
        content: '回答が入ります',
    },
    {
        title: '質問が入ります質問が入ります質問が入ります質問が入ります',
        content: '回答が入ります回答が入ります回答が入ります回答が入ります',
    },
];
