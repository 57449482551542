"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconCoin = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(14), height: (0, scale_1.scale)(15), viewBox: "0 0 14 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.G, { clipPath: "url(#clip0_1_231)" },
        React.createElement(react_native_svg_1.Mask, { id: "mask0_1_231", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: (0, scale_1.scale)(14), height: (0, scale_1.scale)(15) },
            React.createElement(react_native_svg_1.Path, { d: "M14 0.879456H0V14.8795H14V0.879456Z", fill: "white" })),
        React.createElement(react_native_svg_1.G, { mask: "url(#mask0_1_231)" },
            React.createElement(react_native_svg_1.Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M12.2111 7.87984C12.2111 4.90834 9.97112 2.66835 7 2.66835C4.0289 2.66835 1.7889 4.90834 1.7889 7.87984C1.7889 10.8506 4.0289 13.0906 7 13.0906C9.97112 13.0906 12.2111 10.8506 12.2111 7.87984ZM0.777405 7.87984C0.777405 4.47815 3.5983 1.65723 7 1.65723C10.4847 1.65723 13.2226 4.47815 13.2226 7.87984C13.2226 11.2815 10.4017 14.1024 7 14.1024C3.5983 14.1024 0.777405 11.2815 0.777405 7.87984Z", fill: "#292A33" }),
            React.createElement(react_native_svg_1.Path, { d: "M5.14751 10.4073L5.14815 10.408C5.4834 10.7519 5.99098 10.9141 6.64959 10.9141H7.66186C8.20277 10.9141 8.63218 10.7997 8.93509 10.5558C9.24102 10.3095 9.40999 9.92406 9.4568 9.41952C9.47557 9.28021 9.45198 9.15254 9.36186 9.05925C9.27559 8.96994 9.156 8.93267 9.02 8.93267C8.89321 8.93267 8.78131 8.96956 8.69664 9.05323C8.61376 9.13512 8.56842 9.2506 8.54977 9.38576L8.54972 9.38575L8.54939 9.38887C8.5229 9.63836 8.44096 9.78314 8.32562 9.85621C8.2011 9.93391 7.98573 9.98056 7.66186 9.98056H6.64959C6.35529 9.98056 6.13456 9.94474 5.9807 9.87962C5.83396 9.8147 5.73025 9.70787 5.66677 9.5541C5.60329 9.39336 5.5686 9.16341 5.5686 8.85802V6.84955C5.5686 6.54093 5.60336 6.30951 5.66688 6.14883C5.73074 5.99142 5.83334 5.88539 5.97611 5.82373C6.12964 5.75867 6.34875 5.72415 6.64018 5.727L6.64018 5.727H6.64115H7.66186C7.98596 5.727 8.20141 5.77372 8.32589 5.85152L8.32589 5.85152L8.3266 5.85196C8.44058 5.92188 8.52279 6.06542 8.54938 6.31858L8.54932 6.31858L8.54977 6.32181C8.56842 6.45697 8.61376 6.57244 8.69664 6.65434C8.78131 6.73801 8.89321 6.7749 9.02 6.7749C9.156 6.7749 9.27559 6.73762 9.36186 6.64832C9.45198 6.55502 9.47558 6.42735 9.45679 6.28802C9.40999 5.78645 9.24107 5.40278 8.93558 5.15655C8.63263 4.90946 8.20302 4.79347 7.66186 4.79347H6.6416C5.9857 4.78759 5.48035 4.94828 5.14767 5.29571C4.81578 5.6394 4.66001 6.16461 4.66001 6.84955V8.85802C4.66001 9.53763 4.816 10.061 5.14751 10.4073L5.14751 10.4073Z", fill: "#292A33", stroke: "#292A33", strokeWidth: 0.2 }))),
    React.createElement(react_native_svg_1.Defs, null,
        React.createElement(react_native_svg_1.ClipPath, { id: "clip0_1_231" },
            React.createElement(react_native_svg_1.Rect, { width: (0, scale_1.scale)(14), height: (0, scale_1.scale)(14), fill: "white", transform: "translate(0 0.879456)" })))));
exports.default = IconCoin;
