"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    main: {
        paddingTop: (0, scale_1.scale)(24),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    Rectangle: {
        width: 6,
        height: 24,
        backgroundColor: '#0591E8',
    },
    input: {
        height: (0, scale_1.scale)(184),
    },
    err: {
        color: '#FF0000',
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
        width: '100%',
        textAlign: 'left',
    },
});
