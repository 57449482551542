"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    avatarList: {
        flexDirection: "row",
        alignItems: "center",
    },
    avatar: {
        height: (0, scale_1.scale)(25),
        width: (0, scale_1.scale)(25),
        borderRadius: (0, scale_1.scale)(12.5),
        borderWidth: (0, scale_1.scale)(2),
        borderColor: "white",
    },
});
