"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconCalender = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || 20, height: props.height || 20, viewBox: "0 0 20 20", fill: "none", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7839_1323", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 20, height: 20 },
        React.createElement(react_native_svg_1.Rect, { width: 20, height: 20, fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7839_1323)" },
        React.createElement(react_native_svg_1.Path, { d: "M9.26216 11.7309V10.2565H10.7365V11.7309H9.26216ZM5.92883 11.7309V10.2565H7.40316V11.7309H5.92883ZM12.5955 11.7309V10.2565H14.0698V11.7309H12.5955ZM9.26216 15.0001V13.5257H10.7365V15.0001H9.26216ZM5.92883 15.0001V13.5257H7.40316V15.0001H5.92883ZM12.5955 15.0001V13.5257H14.0698V15.0001H12.5955ZM2.91602 17.9167V3.75012H5.57629V1.9873H6.85831V3.75012H13.1724V1.9873H14.4224V3.75012H17.0826V17.9167H2.91602ZM4.16599 16.6668H15.8327V8.58987H4.16599V16.6668ZM4.16599 7.33989H15.8327V5.0001H4.16599V7.33989Z", fill: "#545454" }))));
exports.default = IconCalender;
