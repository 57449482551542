"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const scale_1 = require("../../../utils/scale");
const NUM_OF_CIRCLE = 9;
function calculateCoordinates(centerX, centerY, radius, numObjects, specialObjects) {
    let coordinates = [];
    let numOfCircle = NUM_OF_CIRCLE;
    let objectRadius = (0, scale_1.scale)(90 / 2); // Radius of each object
    let currentRadius = radius;
    let startAngle = 0;
    for (let i = 0; i < numObjects; i++) {
        const angleStep = 360 / Math.min(numObjects, numOfCircle);
        let angle = ((i * angleStep + startAngle) * Math.PI) / 180; // Convert degree to radians
        let x = centerX + currentRadius * Math.cos(angle);
        let y = centerY + currentRadius * Math.sin(angle);
        // Check if new coordinates overlap with existing objects (including specialObjects)
        let isOverlap = checkOverlapWithSpecialObjects(coordinates, x, y, objectRadius, specialObjects);
        if (isOverlap) {
            if (i == numObjects - 1 && coordinates.length == 0) {
                i = -1;
                numObjects = NUM_OF_CIRCLE;
                continue;
            }
            else {
                numObjects += 1;
            }
        }
        else {
            coordinates.push({ x: x, y: y });
        }
        // Check if more than number of objects and create new circle
        if (i === numOfCircle - 1 && numObjects > numOfCircle) {
            currentRadius += objectRadius * 2; // Increase radius for new circle
            i = -1; // Reset index to start from 0
            numObjects -= numOfCircle; // Update remaining objects
            numOfCircle += Math.floor(numOfCircle * 0.8);
            startAngle += 30;
        }
    }
    return coordinates;
}
// Function to check if new coordinates overlap with existing objects (including specialObjects)
function checkOverlapWithSpecialObjects(existingCoords, newX, newY, objectRadius, specialObjects) {
    for (let coord of existingCoords) {
        let distanceSquared = (coord.x - newX) ** 2 + (coord.y - newY) ** 2;
        let minDistanceSquared = (2 * objectRadius) ** 2;
        if (distanceSquared < minDistanceSquared) {
            return true; // Overlap detected with normal object
        }
    }
    for (let specialObj of specialObjects) {
        let distanceSquared = (specialObj.x - newX) ** 2 + (specialObj.y - newY) ** 2;
        let minDistanceSquared = (objectRadius + specialObj.radius) ** 2;
        if (distanceSquared < minDistanceSquared) {
            return true; // Overlap detected with special object
        }
    }
    return false; // No overlap with any objects
}
exports.default = calculateCoordinates;
