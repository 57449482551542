"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("./actions");
const initState = {
    listMute: [],
    isLoading: false,
    error: null,
};
function listMuteReducer(state = initState, action) {
    switch (action.type) {
        case actions_1.LIST_MUTE_START:
            return {
                ...state,
                isLoading: true,
            };
        case actions_1.LIST_MUTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listMute: action.data,
            };
        case actions_1.LIST_MUTE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.data,
            };
        default:
            return state;
    }
}
exports.default = listMuteReducer;
