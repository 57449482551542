"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconClose = (props) => (React.createElement(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 8 8" },
    React.createElement(react_native_svg_1.G, { id: "Group_832", "data-name": "Group 832", transform: "translate(-973.953 -315.953)" },
        React.createElement(react_native_svg_1.Circle, { id: "Ellipse_25", "data-name": "Ellipse 25", cx: "4", cy: "4", r: "4", transform: "translate(973.953 315.953)", fill: "#9d9d9d" }),
        React.createElement(react_native_svg_1.Path, { id: "Path_56", "data-name": "Path 56", d: "M4.566-2.242l.181-.176L3.283-3.887,4.747-5.352l-.181-.176L3.1-4.063,1.642-5.528l-.186.181,1.47,1.459-1.47,1.47.181.176L3.1-3.706Z", transform: "translate(974.851 323.845)", fill: "#f9f9f9", stroke: "#f9f9f9", "stroke-width": "1" }))));
exports.default = IconClose;
