"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    headerText: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: '300',
        color: '#007AFF',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    decor: {
        height: '100%',
        width: (0, scale_1.scale)(6),
        backgroundColor: '#287BCF',
    },
    title: {
        flex: 1,
        marginLeft: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#292A33',
    },
    descriptionBold: {
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(18),
        lineHeight: (0, scale_1.moderateScale)(27),
        color: '#292A33',
    },
    description: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    box: {
        marginTop: (0, scale_1.scale)(24),
    },
    boxTitle: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#545454',
    },
    boxContent: {
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    boxBtn: {
        alignSelf: 'flex-end',
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#007AFF',
    },
    addHashtagBtn: {
        height: (0, scale_1.scale)(40),
        width: '100%',
        // backgroundColor: '#FEFEFE',
        marginTop: (0, scale_1.scale)(32),
    },
    addHashtagLabel: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#1960B0',
    },
    nextBtn: {
        width: '100%',
        height: (0, scale_1.scale)(48),
        borderRadius: (0, scale_1.scale)(4),
    },
    nextBtnLabel: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: '#FEFEFE',
    },
});
