"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconLocation = (props) => (React.createElement(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 18 19", fill: "none" },
    React.createElement(react_native_svg_1.Path, { d: "M9 13.35C10.2994 12.3018 11.2805 11.2928 11.9433 10.3232C12.6061 9.35356 12.9375 8.45 12.9375 7.6125C12.9375 6.875 12.8031 6.25 12.5344 5.7375C12.2656 5.225 11.9343 4.807 11.5405 4.4835C11.1466 4.15999 10.7199 3.92561 10.2604 3.78038C9.80086 3.63512 9.38074 3.5625 9 3.5625C8.61926 3.5625 8.19914 3.63512 7.73963 3.78038C7.28011 3.92561 6.85342 4.15999 6.45954 4.4835C6.06568 4.807 5.73438 5.225 5.46563 5.7375C5.19688 6.25 5.0625 6.875 5.0625 7.6125C5.0625 8.45 5.39391 9.35356 6.05672 10.3232C6.71953 11.2928 7.70063 12.3018 9 13.35ZM9 14.775C7.2875 13.4875 6.01562 12.255 5.18438 11.0775C4.35313 9.90002 3.9375 8.74656 3.9375 7.61713C3.9375 6.76404 4.09062 6.01562 4.39688 5.37188C4.70313 4.72813 5.1 4.1875 5.5875 3.75C6.075 3.3125 6.61863 2.98438 7.21838 2.76562C7.81813 2.54688 8.41188 2.4375 8.99963 2.4375C9.58738 2.4375 10.1813 2.54688 10.7813 2.76562C11.3813 2.98438 11.925 3.3125 12.4125 3.75C12.9 4.1875 13.2969 4.72833 13.6031 5.37249C13.9094 6.01666 14.0625 6.76618 14.0625 7.62105C14.0625 8.75285 13.6469 9.90625 12.8156 11.0813C11.9844 12.2563 10.7125 13.4875 9 14.775ZM9.00167 8.8125C9.36306 8.8125 9.67188 8.68382 9.92813 8.42646C10.1844 8.16911 10.3125 7.85973 10.3125 7.49833C10.3125 7.13694 10.1838 6.82812 9.92646 6.57188C9.66911 6.31563 9.35973 6.1875 8.99833 6.1875C8.63694 6.1875 8.32812 6.31618 8.07188 6.57354C7.81563 6.83089 7.6875 7.14027 7.6875 7.50167C7.6875 7.86306 7.81618 8.17188 8.07354 8.42813C8.33089 8.68438 8.64027 8.8125 9.00167 8.8125ZM3.9375 17.4375V16.3125H14.0625V17.4375H3.9375Z", fill: props?.color || "#545454" })));
exports.default = IconLocation;
