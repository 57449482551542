"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.articleCreatingReducer = exports.destroyArticleAction = exports.addHashtagsAction = exports.createArticleAction = exports.LoginState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.LoginState = {
    CREATE_ARTICLE: 'CREATE_ARTICLE',
    ADD_HASHTAGS: 'ADD_HASHTAGS',
};
const initialState = {
    article_id: '',
    inherited_from_article_id: '',
    inherited_from_user_id: undefined,
    inherited_from_user_id_root: undefined,
    inherited_from_user_nickname: '',
    inherited_from_user_handle: '',
    inherited_from_user_thumbnail: '',
    title: '',
    thumbnail_image: '',
    content_type: undefined,
    full_text: '',
    hashtags: [],
    status: 1,
};
// create your action
exports.createArticleAction = (0, toolkit_1.createAction)('createArticleAction');
exports.addHashtagsAction = (0, toolkit_1.createAction)('addHashtagsAction');
exports.destroyArticleAction = (0, toolkit_1.createAction)('destroyArticleAction');
const articleCreatingSlice = (0, toolkit_1.createSlice)({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(exports.createArticleAction, (state, action) => {
            return { ...state, ...action.payload };
        });
        builder.addCase(exports.addHashtagsAction, (state, action) => {
            return { ...state, hashtags: action.payload };
        });
        builder.addCase(exports.destroyArticleAction, (_) => {
            return initialState;
        });
    },
});
exports.articleCreatingReducer = articleCreatingSlice.reducer;
exports.default = articleCreatingSlice;
