"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IC_ERROR = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(52), height: props.height || (0, scale_1.scale)(52), viewBox: "0 0 53 52", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_8452_14450", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "53", height: "52" },
        React.createElement(react_native_svg_1.Rect, { x: "0.5", width: "52", height: "52", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_8452_14450)" },
        React.createElement(react_native_svg_1.Path, { d: "M6.40412 45.5C6.0069 45.5 5.64578 45.4007 5.32078 45.2021C4.99578 45.0035 4.74301 44.7417 4.56245 44.4167C4.3819 44.0917 4.28259 43.7396 4.26453 43.3604C4.24648 42.9813 4.34578 42.6111 4.56245 42.25L24.6041 7.58333C24.8208 7.22222 25.1006 6.95139 25.4437 6.77083C25.7868 6.59028 26.1388 6.5 26.5 6.5C26.8611 6.5 27.2131 6.59028 27.5562 6.77083C27.8993 6.95139 28.1791 7.22222 28.3958 7.58333L48.4375 42.25C48.6541 42.6111 48.7534 42.9813 48.7354 43.3604C48.7173 43.7396 48.618 44.0917 48.4375 44.4167C48.2569 44.7417 48.0041 45.0035 47.6791 45.2021C47.3541 45.4007 46.993 45.5 46.5958 45.5H6.40412ZM10.1416 41.1667H42.8583L26.5 13L10.1416 41.1667ZM26.5 39C27.1138 39 27.6284 38.7924 28.0437 38.3771C28.459 37.9618 28.6666 37.4472 28.6666 36.8333C28.6666 36.2194 28.459 35.7049 28.0437 35.2896C27.6284 34.8743 27.1138 34.6667 26.5 34.6667C25.8861 34.6667 25.3715 34.8743 24.9562 35.2896C24.5409 35.7049 24.3333 36.2194 24.3333 36.8333C24.3333 37.4472 24.5409 37.9618 24.9562 38.3771C25.3715 38.7924 25.8861 39 26.5 39ZM26.5 32.5C27.1138 32.5 27.6284 32.2924 28.0437 31.8771C28.459 31.4618 28.6666 30.9472 28.6666 30.3333V23.8333C28.6666 23.2194 28.459 22.7049 28.0437 22.2896C27.6284 21.8743 27.1138 21.6667 26.5 21.6667C25.8861 21.6667 25.3715 21.8743 24.9562 22.2896C24.5409 22.7049 24.3333 23.2194 24.3333 23.8333V30.3333C24.3333 30.9472 24.5409 31.4618 24.9562 31.8771C25.3715 32.2924 25.8861 32.5 26.5 32.5Z", fill: props?.color || "#C8C8C8" }))));
exports.default = IC_ERROR;
