"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const icons_1 = require("../../../../assets/icons");
const components_1 = require("../../../../components");
const helpler_1 = require("../../../../utils/helpler");
const scale_1 = require("../../../../utils/scale");
const BackgroundEditor = (props) => {
    const { defaultSource = '', onChange } = props;
    const [backgroundSource, setBackgroundSource] = (0, react_1.useState)(defaultSource);
    const [imagePickedSource, setImagePickedSource] = (0, react_1.useState)('');
    const [imagePickedType, setImagePickedType] = (0, react_1.useState)('image/jpeg');
    const modalVisible = !!imagePickedSource;
    const onBackgroundPress = () => {
        if (!helpler_1.isWeb || !!imagePickedSource || backgroundSource)
            return;
        const inputElement = document.getElementById('background-file-upload');
        if (inputElement) {
            inputElement.click();
        }
    };
    const handlePickImage = (event) => {
        const file = event.target.files?.[0];
        if (!file)
            return;
        const isValid = (0, helpler_1.validateFile)(file, 5, 'image');
        if (!isValid)
            return;
        // @ts-ignore
        const url = URL.createObjectURL(file);
        setImagePickedSource(url);
        setImagePickedType(file.type);
    };
    const handleApplyImageChange = (imageUrl) => {
        if (imageUrl) {
            setBackgroundSource(imageUrl);
            onChange(imageUrl);
        }
        setImagePickedSource('');
    };
    const handleRemoveBackground = () => {
        setBackgroundSource('');
        onChange('');
    };
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { activeOpacity: 0.8, style: styles.backgroundImage, onPress: onBackgroundPress },
        backgroundSource && (react_1.default.createElement(react_native_1.TouchableOpacity, { activeOpacity: 0.8, style: styles.removeButton, onPress: handleRemoveBackground },
            react_1.default.createElement(icons_1.IC_X, { width: (0, scale_1.scale)(20.53), height: (0, scale_1.scale)(20.53), color: "#FFF" }))),
        helpler_1.isWeb && (react_1.default.createElement("input", { type: "file", accept: "image/png, image/jpeg, image/jpg, image/webp", value: "", onChange: handlePickImage, style: { display: 'none' }, id: "background-file-upload" })),
        !!backgroundSource && (react_1.default.createElement(react_native_1.Image, { source: { uri: backgroundSource }, style: styles.image, resizeMode: "cover" })),
        !backgroundSource && (react_1.default.createElement(react_native_1.View, { style: styles.absoluteView },
            react_1.default.createElement(icons_1.IC_ADD_IMAGE, { width: (0, scale_1.scale)(30), height: (0, scale_1.scale)(30) }))),
        react_1.default.createElement(components_1.ModalCropImage, { visible: modalVisible, imageSource: imagePickedSource, imageType: imagePickedType, onClose: handleApplyImageChange, cropSize: { width: (0, scale_1.scale)(300), height: (0, scale_1.scale)(100) }, cropShape: "rect", saveButtonLabel: "\u4FDD\u5B58" })));
};
exports.default = BackgroundEditor;
const styles = react_native_1.StyleSheet.create({
    backgroundImage: {
        width: '100%',
        height: (0, scale_1.scale)(125),
        backgroundColor: '#000000',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    image: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F7F7F7',
    },
    absoluteView: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    },
    removeButton: {
        position: 'absolute',
        zIndex: 10,
        top: (0, scale_1.scale)(4),
        right: (0, scale_1.scale)(4),
        width: (0, scale_1.scale)(30),
        height: (0, scale_1.scale)(30),
        backgroundColor: '#000000CC',
        borderRadius: 999,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
