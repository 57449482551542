"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 10,
    },
    underlineCheckboxLabel: {
        textDecorationLine: 'underline',
        marginLeft: 8,
        fontSize: 14,
        fontWeight: '300',
        color: '#EF5454',
    },
    checkboxLabel: {
        fontSize: (0, scale_1.moderateScale)(14),
        fontWeight: '300',
        color: '#0E0E0E',
        lineHeight: (0, scale_1.scale)(20),
        marginLeft: (0, scale_1.scale)(8),
    },
});
