"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconCheckModal = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(17), height: (0, scale_1.scale)(17), viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Path, { d: "M7.25816 12.2221L13.1408 6.33941L12.2187 5.41731L7.25816 10.3779L4.76441 7.88413L3.84231 8.80623L7.25816 12.2221ZM8.50144 16.8125C7.35175 16.8125 6.2711 16.5943 5.25948 16.158C4.24785 15.7216 3.36788 15.1295 2.61956 14.3815C1.87123 13.6335 1.27881 12.7539 0.842285 11.7427C0.405762 10.7316 0.1875 9.65114 0.1875 8.50144C0.1875 7.35175 0.405667 6.2711 0.842 5.25948C1.27833 4.24785 1.87049 3.36788 2.61847 2.61956C3.36646 1.87123 4.24605 1.27881 5.25723 0.842285C6.26839 0.405762 7.34882 0.1875 8.49851 0.1875C9.6482 0.1875 10.7289 0.405668 11.7405 0.842001C12.7521 1.27833 13.6321 1.87049 14.3804 2.61847C15.1287 3.36646 15.7212 4.24605 16.1577 5.25723C16.5942 6.26839 16.8125 7.34882 16.8125 8.49851C16.8125 9.64821 16.5943 10.7289 16.158 11.7405C15.7216 12.7521 15.1295 13.6321 14.3815 14.3804C13.6335 15.1287 12.7539 15.7212 11.7427 16.1577C10.7316 16.5942 9.65114 16.8125 8.50144 16.8125Z", fill: "#FEFEFE" })));
exports.default = IconCheckModal;
