"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appReducer = exports.setShowGuildline = exports.setLoadedAppAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoaded: false,
    isShowGuildline: false,
    toast: '',
    isSuccessToast: true,
    isMaintaining: false,
    requestLoginModal: false,
    connected: true,
};
exports.setLoadedAppAction = (0, toolkit_1.createAction)('setLoadedApp');
exports.setShowGuildline = (0, toolkit_1.createAction)('setShowGuildline');
const appSlice = (0, toolkit_1.createSlice)({
    name: 'app',
    initialState,
    reducers: {
        setAppRole: (state, action) => {
            return { ...state, appRole: action.payload };
        },
        logout: (state) => {
            return state;
        },
        withDraw: (state) => {
            return state;
        },
        initDataApp: () => { },
        setToastType: (state, action) => {
            return { ...state, isSuccessToast: action.payload };
        },
        showToast: (state, action) => {
            return { ...state, toast: action.payload };
        },
        hideToast: (state) => {
            return { ...state, toast: '', isSuccessToast: true };
        },
        showMaintainModal: (state) => {
            return { ...state, isMaintaining: true };
        },
        showRequestLoginModal: (state) => {
            return { ...state, requestLoginModal: true };
        },
        hideRequestLoginModal: (state) => {
            return { ...state, requestLoginModal: false };
        },
        updateConnectionStatus: (state, action) => {
            return { ...state, connected: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.setLoadedAppAction, (state, action) => {
            return {
                ...state,
                isLoaded: action.payload,
            };
        });
        builder.addCase(exports.setShowGuildline, (state, action) => {
            return {
                ...state,
                isShowGuildline: action.payload,
            };
        });
    },
});
exports.appReducer = appSlice.reducer;
exports.default = appSlice;
