"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const helpler_1 = require("../../utils/helpler");
const scale_1 = require("../../utils/scale");
const ModalSubUser_1 = require("./ModalSubUser");
const ModalSubMyPost = (props) => {
    const { visible, onClose, modalPosition, deletePost, edit, openModalShare } = props;
    const distanceFromBottom = react_native_1.Dimensions.get('window').height - modalPosition.y;
    const [modalHeight, setModalHeight] = (0, react_1.useState)(0);
    return (react_1.default.createElement(react_native_1.Modal, { visible: visible, animationType: "none", statusBarTranslucent: true, transparent: true, onRequestClose: onClose, onShow: () => {
            helpler_1.isWeb && document.body.classList.add("prevent-slice-modal");
        }, onDismiss: () => {
            helpler_1.isWeb && document.body.classList.remove("prevent-slice-modal");
        }, ...props },
        react_1.default.createElement(react_native_1.TouchableOpacity, { activeOpacity: 1, style: styles.viewContain, onPress: onClose },
            react_1.default.createElement(react_native_1.View, { style: [
                    styles.viewModal,
                    {
                        position: 'absolute',
                        top: distanceFromBottom > (0, scale_1.scale)(140)
                            ? modalPosition.y + (0, scale_1.scale)(4)
                            : modalPosition.y - modalHeight - (0, scale_1.scale)(26 + 4),
                        left: modalPosition.x - (0, scale_1.scale)(220),
                    },
                ], onLayout: (e) => setModalHeight(e.nativeEvent.layout.height) },
                react_1.default.createElement(ModalSubUser_1.MenuItemPressButton, { title: '\u7DE8\u96C6', onPress: edit }),
                react_1.default.createElement(ModalSubUser_1.MenuItemPressButton, { title: '\u524A\u9664', onPress: deletePost }),
                react_1.default.createElement(ModalSubUser_1.MenuItemPressButton, { title: '\u5171\u6709', onPress: openModalShare, style: { borderBottomColor: 'transparent' } })),
            react_1.default.createElement(react_native_1.View, { style: {
                    marginBottom: (0, scale_1.scale)(40),
                    marginTop: (0, scale_1.scale)(20),
                    maxHeight: (0, scale_1.scale)(370),
                } }))));
};
const styles = react_native_1.StyleSheet.create({
    viewModal: {
        backgroundColor: '#DDDDDD',
        width: (0, scale_1.scale)(220),
        borderRadius: (0, scale_1.scale)(6),
        overflow: 'hidden'
    },
    viewContain: {
        flex: 1,
    },
});
exports.default = ModalSubMyPost;
