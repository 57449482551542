"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconDown = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(11), height: (0, scale_1.scale)(7), viewBox: "0 0 11 7", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Path, { d: "M5.49984 6.56614C5.38434 6.56614 5.27684 6.54771 5.17733 6.51085C5.07781 6.474 4.98321 6.41073 4.89352 6.32103L0.586559 2.01406C0.453862 1.88138 0.38598 1.7146 0.382913 1.51371C0.37983 1.31285 0.447712 1.143 0.586559 1.00417C0.725389 0.865322 0.893704 0.795898 1.0915 0.795898C1.2893 0.795898 1.45762 0.865322 1.59645 1.00417L5.49984 4.90753L9.40322 1.00417C9.53591 0.871471 9.70269 0.803589 9.90357 0.800522C10.1044 0.79744 10.2743 0.865322 10.4131 1.00417C10.552 1.143 10.6214 1.31131 10.6214 1.50911C10.6214 1.70691 10.552 1.87523 10.4131 2.01406L6.10615 6.32103C6.01647 6.41073 5.92186 6.474 5.82234 6.51085C5.72283 6.54771 5.61533 6.56614 5.49984 6.56614Z", fill: "#1C1B1F" })));
exports.default = IconDown;
