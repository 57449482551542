"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconLiked = (props) => (React.createElement(react_native_svg_1.default, { width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_9755_7235", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18) },
        React.createElement(react_native_svg_1.Rect, { width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18), fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_9755_7235)" },
        React.createElement(react_native_svg_1.Path, { d: "M15.5192 6.37466C15.8759 6.37466 16.1911 6.51144 16.4646 6.785C16.7382 7.05855 16.875 7.37369 16.875 7.73043V8.94194C16.875 9.01983 16.8668 9.10396 16.8504 9.19434C16.8341 9.28472 16.8096 9.36886 16.7769 9.44675L14.6279 14.5179C14.5202 14.7583 14.3399 14.9612 14.087 15.1265C13.8341 15.2919 13.5711 15.3746 13.298 15.3746H7.22593C6.85189 15.3746 6.53243 15.2422 6.26753 14.9772C6.00261 14.7123 5.87016 14.3929 5.87016 14.0188V6.93571C5.87016 6.75495 5.90718 6.58116 5.98121 6.41433C6.05525 6.24751 6.15381 6.10256 6.2769 5.97948L10.2447 2.03765C10.3937 1.89631 10.5663 1.80929 10.7624 1.77659C10.9586 1.74391 11.1466 1.77324 11.3264 1.86459C11.5062 1.95592 11.636 2.09006 11.7158 2.26698C11.7956 2.44389 11.8115 2.63187 11.7634 2.83091L10.9557 6.37466H15.5192ZM3.23078 15.3746C2.85674 15.3746 2.53727 15.2422 2.27237 14.9772C2.00746 14.7123 1.875 14.3929 1.875 14.0188V7.73043C1.875 7.35639 2.00746 7.03692 2.27237 6.77202C2.53727 6.50711 2.85674 6.37466 3.23078 6.37466H3.38944C3.76348 6.37466 4.08294 6.50711 4.34784 6.77202C4.61274 7.03692 4.74519 7.35639 4.74519 7.73043V14.0261C4.74519 14.4001 4.61274 14.7184 4.34784 14.9809C4.08294 15.2434 3.76348 15.3746 3.38944 15.3746H3.23078Z", fill: props?.color || "#3FA841" }))));
exports.default = IconLiked;
