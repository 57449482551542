"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.articleLikeReducer = exports.addArticleToggleLikeAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    article_id: '',
    isLike: false,
};
// create your action
exports.addArticleToggleLikeAction = (0, toolkit_1.createAction)('addArticleToggleLike');
const articleLikeSlice = (0, toolkit_1.createSlice)({
    name: 'articleLike',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(exports.addArticleToggleLikeAction, (_, action) => {
            return action.payload;
        });
    },
});
exports.articleLikeReducer = articleLikeSlice.reducer;
exports.default = articleLikeSlice;
