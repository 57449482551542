"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    container: {
        flex: 1,
        backgroundColor: "#F7F7F7",
    },
    body: {
        flex: 1,
        paddingTop: (0, scale_1.scale)(24),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    rectangle: {
        width: (0, scale_1.scale)(6),
        height: (0, scale_1.scale)(24),
        backgroundColor: "#0591E8",
    },
    title: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: "#292A33",
        paddingLeft: (0, scale_1.scale)(8),
    },
    description: {
        width: "100%",
        marginTop: (0, scale_1.scale)(12),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: "#292A33",
    },
    backButton: {
        width: "100%",
        height: (0, scale_1.scale)(48),
        marginTop: (0, scale_1.scale)(32),
        borderRadius: (0, scale_1.scale)(4),
    },
    backButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        color: "#1960B0",
    },
});
