"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconReset = (props) => (React.createElement(react_native_svg_1.default, { width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_5867_8788", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18) },
        React.createElement(react_native_svg_1.Rect, { width: props?.height || (0, scale_1.scale)(18), height: props?.height || (0, scale_1.scale)(18), fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_5867_8788)" },
        React.createElement(react_native_svg_1.Path, { d: "M9.27414 15.4922L6.33753 15.4922C5.32195 15.4922 4.11672 15.4742 3.40092 14.7709C2.68512 14.0677 2.66677 12.8836 2.66677 11.8858L2.66677 5.25011L1.03367 6.83691L0.00585895 5.82713L3.40092 2.50927L6.79605 5.82713L5.76824 6.83691L4.13507 5.25011L4.13507 11.8858C4.13507 12.4869 4.07389 13.2683 4.50215 13.689C4.9304 14.1097 5.72574 14.0496 6.33753 14.0496L9.27414 14.0496L9.27414 15.4922Z", fill: props.color }),
        React.createElement(react_native_svg_1.Path, { d: "M8.73172 2.50879L11.6683 2.50879C12.6839 2.50879 13.8891 2.52682 14.6049 3.23006C15.3207 3.9333 15.3391 5.11739 15.3391 6.11515L15.3391 12.7509L16.9722 11.1641L18 12.1738L14.6049 15.4917L11.2098 12.1738L12.2376 11.1641L13.8708 12.7509L13.8708 6.11515C13.8708 5.51409 13.932 4.73271 13.5037 4.31197C13.0755 3.89123 12.2801 3.95134 11.6683 3.95133L8.73172 3.95133L8.73172 2.50879Z", fill: props.color }))));
exports.default = IconReset;
