"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    content: {},
    loginForm: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: (0, scale_1.scale)(25),
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    input: {
        width: (0, scale_1.scale)(343),
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    buttonContainer: {
        width: '100%',
        backgroundColor: 'red',
        justifyContent: 'center',
        height: 40,
        marginTop: 15,
        borderRadius: 20,
        paddingHorizontal: 10,
    },
    imageContainer: {
        position: 'absolute',
        top: 30,
        resizeMode: 'contain',
        height: '30%',
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        borderRadius: 25,
    },
    underlineButton: {
        color: 'black',
        textDecorationLine: 'underline',
        fontWeight: '600',
        paddingBottom: 20,
    },
    textButton: {
        color: 'white',
        fontWeight: '600',
    },
    textBottom: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: '300',
        color: '#292A33',
    },
    iconInputLogin: {
    // alignSelf: "center",
    // zIndex: 1,
    },
    blurView: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    loginFieldContainer: {
        flexDirection: 'row',
        paddingHorizontal: (0, scale_1.scale)(8),
        height: (0, scale_1.scale)(40),
        borderRadius: (0, scale_1.scale)(3),
        marginTop: (0, scale_1.scale)(5),
        overflow: 'hidden',
        backgroundColor: 'white',
    },
});
