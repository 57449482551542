"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconRedirect = (props) => (React.createElement(react_native_svg_1.default, { width: props?.width || (0, scale_1.scale)(14), height: props?.height || (0, scale_1.scale)(14), viewBox: "0 0 14 15", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7422_15081", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "14", height: "15" },
        React.createElement(react_native_svg_1.Rect, { y: "0.5", width: "14", height: "14", fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7422_15081)" },
        React.createElement(react_native_svg_1.Path, { d: "M2.04175 12.4596V2.54297H6.77569V3.41795H2.91673V11.5846H11.0834V7.72567H11.9584V12.4596H2.04175ZM5.66962 9.44647L5.05488 8.83174L10.4687 3.41795H8.16673V2.54297H11.9584V6.33462H11.0834V4.03269L5.66962 9.44647Z", fill: "#007AFF" }))));
exports.default = IconRedirect;
