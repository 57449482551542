"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginForm: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
    },
    input: {
        height: 40,
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'gray',
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    bottomButton: {
        position: 'absolute',
        right: (0, scale_1.scale)(16),
        bottom: (0, scale_1.scale)(90),
        height: (0, scale_1.scale)(40),
        width: (0, scale_1.scale)(40),
        alignItems: 'center',
    },
    inputText: {
        width: (0, scale_1.scale)(291),
        fontSize: (0, scale_1.moderateScale)(15),
        // lineHeight: moderateScale(23),
        color: '#292A33',
        marginTop: (0, scale_1.scale)(12),
        outlineWidth: 0,
        fontFamily: 'Hiragino Kaku Gothic Pro W3',
        paddingRight: (0, scale_1.scale)(16),
    },
    inputTextPlaceholder: {
        width: (0, scale_1.scale)(291),
        fontSize: (0, scale_1.moderateScale)(15),
        // lineHeight: moderateScale(23),
        color: '#C8C8C8',
        marginTop: (0, scale_1.scale)(12),
        outlineWidth: 0,
        fontFamily: 'Hiragino Kaku Gothic Pro W3',
    },
    inputTextContent: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(23),
    },
});
