"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        fontWeight: '300',
        color: '#007AFF',
    },
    editContainer: {
        flex: 1,
        marginTop: (0, scale_1.scale)(12),
        position: 'relative',
    },
    editView: {
        flex: 1,
        marginTop: (0, scale_1.scale)(16),
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    richEditor: {
        height: 300,
        backgroundColor: '#fff',
    },
    input: {
        height: 40,
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'gray',
        marginTop: 10,
        paddingHorizontal: 20,
    },
    loginTextButton: {
        color: 'white',
        textAlign: 'center',
        fontWeight: '600',
    },
    buttonContainer: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
});
