"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigate = exports.setParams = exports.getCurrentRoute = exports.push = exports.replace = exports.goBack = exports.pop = exports.reset = void 0;
const React = __importStar(require("react"));
const native_1 = require("@react-navigation/native");
const react_native_1 = require("react-native");
const navigationRef = React.createRef();
function reset(params) {
    navigationRef?.current?.dispatch(native_1.CommonActions.reset({
        index: params?.index,
        routes: [{ name: params?.routeName, params: params?.params }],
    }));
}
exports.reset = reset;
function pop() {
    navigationRef?.current?.dispatch(native_1.CommonActions.goBack());
}
exports.pop = pop;
function goBack() {
    if (navigationRef?.current?.canGoBack) {
        navigationRef?.current?.goBack();
    }
    else if (react_native_1.Platform.OS === "web") {
        history.go(-1);
    }
}
exports.goBack = goBack;
function replace(routeName, params = {}) {
    navigationRef?.current?.dispatch(native_1.StackActions.replace(routeName, params));
}
exports.replace = replace;
function push(routeName, params = {}) {
    navigationRef?.current?.dispatch(native_1.StackActions.push(routeName, params));
}
exports.push = push;
function getCurrentRoute() {
    const rootState = navigationRef?.current?.getRootState();
    const currentRoute = navigationRef?.current?.getCurrentRoute();
    return { ...currentRoute, index: rootState?.index };
}
exports.getCurrentRoute = getCurrentRoute;
function setParams(argument) {
    navigationRef?.current?.dispatch({
        ...native_1.CommonActions.setParams(argument?.params),
        source: argument?.source,
    });
}
exports.setParams = setParams;
function navigate(routeName, params) {
    //@ts-ignore
    navigationRef?.current?.navigate(routeName, params);
}
exports.navigate = navigate;
exports.default = {
    navigationRef,
};
