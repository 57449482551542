"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: '#F7F7F7',
        justifyContent: 'space-between',
    },
    headerLabel: {
        fontSize: (0, scale_1.moderateScale)(12),
        lineHeight: (0, scale_1.moderateScale)(18),
        color: '#007AFF',
    },
    body: {
        flex: 1,
    },
    main: {
        flex: 1,
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    inputLabel: {
        marginTop: (0, scale_1.scale)(20),
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(21),
        color: '#292A33',
    },
    inputRequireView: {
        marginLeft: (0, scale_1.scale)(10),
        paddingVertical: (0, scale_1.scale)(1),
        paddingHorizontal: (0, scale_1.scale)(6),
        borderRadius: (0, scale_1.scale)(3),
        backgroundColor: '#D32F2F',
    },
    inputRequireLabel: {
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#FEFEFE',
    },
    textInput: {
        width: '100%',
        marginTop: (0, scale_1.scale)(8),
        fontSize: (0, scale_1.moderateScale)(15),
        color: '#292A33',
        paddingHorizontal: (0, scale_1.scale)(10),
        paddingVertical: (0, scale_1.scale)(8.5),
        borderWidth: (0, scale_1.scale)(1),
        borderRadius: (0, scale_1.scale)(3),
        borderColor: '#C8C8C8',
        backgroundColor: '#FEFEFE',
    },
    introduceVideoNote: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(11),
        lineHeight: (0, scale_1.moderateScale)(18.7),
        color: '#545454',
    },
    submitButton: {
        width: '100%',
        height: (0, scale_1.scale)(48),
        marginTop: (0, scale_1.scale)(32),
        borderRadius: (0, scale_1.scale)(4),
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitButtonLabel: {
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
    },
    errorMessage: {
        marginTop: (0, scale_1.scale)(4),
        fontSize: (0, scale_1.moderateScale)(12),
        color: '#D32F2F',
    },
});
