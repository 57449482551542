"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconShare = (props) => (React.createElement(react_native_svg_1.default, { width: (0, scale_1.scale)(42), height: (0, scale_1.scale)(42), viewBox: "0 0 42 42", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_2987_44878", maskUnits: "userSpaceOnUse", x: 0, y: 0, width: (0, scale_1.scale)(42), height: (0, scale_1.scale)(42) },
        React.createElement(react_native_svg_1.Rect, { width: (0, scale_1.scale)(42), height: (0, scale_1.scale)(42), fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_2987_44878)" },
        React.createElement(react_native_svg_1.Path, { d: "M25.1406 28.0915L20.4765 32.7556C18.9227 34.3094 17.0508 35.0864 14.8607 35.0865C12.6705 35.0866 10.7987 34.3099 9.24511 32.7563C7.69153 31.2028 6.91459 29.3307 6.91428 27.1401C6.91397 24.9495 7.6907 23.0774 9.24446 21.5236L13.9086 16.8595L15.7647 18.7155L11.1006 23.3797C10.0614 24.4188 9.54185 25.6721 9.54185 27.1396C9.54185 28.6071 10.0614 29.8604 11.1006 30.8995C12.1397 31.9387 13.393 32.4582 14.8605 32.4582C16.328 32.4582 17.5813 31.9387 18.6204 30.8995L23.2845 26.2354L25.1406 28.0915ZM17.2877 26.5684L15.4316 24.7124L24.7124 15.4316L26.5685 17.2877L17.2877 26.5684ZM28.0915 25.1406L26.2354 23.2845L30.8995 18.6204C31.9387 17.5813 32.4583 16.3279 32.4583 14.8605C32.4583 13.393 31.9387 12.1397 30.8995 11.1005C29.8604 10.0614 28.6071 9.54184 27.1396 9.54184C25.6722 9.54184 24.4188 10.0614 23.3797 11.1005L18.7156 15.7647L16.8595 13.9086L21.5236 9.24445C23.0774 7.69069 24.9493 6.91373 27.1394 6.91359C29.3296 6.91344 31.2014 7.69016 32.755 9.24374C34.3086 10.7973 35.0855 12.6694 35.0858 14.86C35.0861 17.0505 34.3094 18.9227 32.7556 20.4765L28.0915 25.1406Z", fill: "#292A33" }))));
exports.default = IconShare;
