"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const IconMindMapUp = (props) => (React.createElement(react_native_svg_1.default, { width: props.width || (0, scale_1.scale)(30), height: props.width || (0, scale_1.scale)(30), viewBox: "0 0 30 30", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_13187_16708", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "30", height: "30" },
        React.createElement(react_native_svg_1.Rect, { width: "30", height: "30", fill: "#D9D9D9" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_13187_16708)" },
        React.createElement(react_native_svg_1.Path, { d: "M10 24.375C9.73437 24.375 9.51173 24.2851 9.33206 24.1053C9.15237 23.9255 9.06253 23.7028 9.06253 23.4371C9.06253 23.1713 9.15237 22.9487 9.33206 22.7692C9.51173 22.5897 9.73437 22.5 10 22.5H20C20.2656 22.5 20.4883 22.5899 20.668 22.7697C20.8476 22.9494 20.9375 23.1722 20.9375 23.4379C20.9375 23.7036 20.8476 23.9262 20.668 24.1057C20.4883 24.2852 20.2656 24.375 20 24.375H10ZM14.9996 19.7596C14.7338 19.7596 14.5112 19.6698 14.3317 19.4901C14.1523 19.3104 14.0625 19.0878 14.0625 18.8221V9.19225L11.1346 12.101C10.9615 12.274 10.7492 12.3618 10.4976 12.3642C10.246 12.3666 10.0266 12.2748 9.83928 12.0889C9.66759 11.9185 9.58175 11.7017 9.58175 11.4383C9.58175 11.175 9.66829 10.9568 9.84137 10.7837L14.2092 6.41591C14.3261 6.29893 14.4501 6.2164 14.5809 6.16832C14.7118 6.12023 14.852 6.09619 15.0016 6.09619C15.1512 6.09619 15.2909 6.12023 15.4207 6.16832C15.5505 6.2164 15.6739 6.29893 15.7908 6.41591L20.1707 10.7957C20.3437 10.9688 20.4335 11.1811 20.4399 11.4327C20.4463 11.6843 20.3566 11.9037 20.1707 12.091C20.0003 12.2627 19.7834 12.3486 19.52 12.3486C19.2567 12.3486 19.0385 12.262 18.8654 12.0889L15.9375 9.19225V18.8221C15.9375 19.0878 15.8476 19.3104 15.6678 19.4901C15.4881 19.6698 15.2653 19.7596 14.9996 19.7596Z", fill: "#292A33" }))));
exports.default = IconMindMapUp;
