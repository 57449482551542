"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const icons_1 = require("../../assets/icons");
const images_1 = require("../../assets/images");
const helpler_1 = require("../../utils/helpler");
const scale_1 = require("../../utils/scale");
const ImagePickers = (props) => {
    const [file, setFile] = (0, react_1.useState)("");
    const onClick = () => {
        const inputElement = document.getElementById("file-upload");
        if (inputElement) {
            inputElement.click();
            inputElement.onclick = function () {
                // @ts-ignore
                this.value = null;
                this.removeEventListener('click', () => { });
            };
        }
    };
    const handleImageUploadWeb = (event) => {
        const file = event.target.files?.[0];
        if (!file)
            return;
        const isValid = (0, helpler_1.validateFile)(file, 5, 'image');
        if (!isValid)
            return;
        const reader = new FileReader();
        reader.onload = (event) => {
            const imgDataUrl = event?.target?.result;
            setFile(imgDataUrl);
            const images = [imgDataUrl];
            const dataImage = [file];
            if (props.formDataImage) {
                props.formDataImage(dataImage);
            }
        };
        reader.readAsDataURL(file);
    };
    if (react_native_1.Platform.OS === "web") {
        return (react_1.default.createElement(react_native_1.View, { style: styles.container },
            react_1.default.createElement("input", { type: "file", accept: "image/png, image/jpeg, image/jpg, image/webp", onChange: handleImageUploadWeb, style: { display: "none" }, id: "file-upload" }),
            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onClick },
                react_1.default.createElement(react_native_1.Image, { source: file ? { uri: file } : images_1.images.UploadImg, style: { width: (0, scale_1.scale)(311), height: (0, scale_1.scale)(175), borderRadius: (0, scale_1.scale)(3) } })),
            !!file && (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: () => {
                    setFile("");
                    props.formDataImage([]);
                }, style: styles.removeImageBtn },
                react_1.default.createElement(icons_1.IC_REMOVE_IMAGE, null)))));
    }
};
const styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    removeImageBtn: {
        position: "absolute",
        top: (0, scale_1.scale)(4),
        right: (0, scale_1.scale)(4),
        width: (0, scale_1.scale)(30),
        height: (0, scale_1.scale)(30),
        borderRadius: 999,
        backgroundColor: "#000000CC",
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    }
});
exports.default = ImagePickers;
