"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rotateSize = exports.getRadianAngle = exports.createImage = void 0;
const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
});
exports.createImage = createImage;
function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}
exports.getRadianAngle = getRadianAngle;
/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}
exports.rotateSize = rotateSize;
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
async function getCroppedImg(imageSrc, imageType, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
    const image = await (0, exports.createImage)(imageSrc);
    const maxDimension = Math.sqrt(16777216);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }
    // Calculate the scaling factor to ensure the image does not exceed the maximum dimension
    const scaleFactor = Math.min(maxDimension / image.width, maxDimension / image.height, 1);
    const rotRad = getRadianAngle(rotation);
    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width * scaleFactor, image.height * scaleFactor, rotation);
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate((-image.width * scaleFactor) / 2, (-image.height * scaleFactor) / 2);
    // draw rotated image
    ctx.drawImage(image, 0, 0, image.width * scaleFactor, image.height * scaleFactor);
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    if (!croppedCtx) {
        return null;
    }
    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width * scaleFactor;
    croppedCanvas.height = pixelCrop.height * scaleFactor;
    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(canvas, pixelCrop.x * scaleFactor, pixelCrop.y * scaleFactor, pixelCrop.width * scaleFactor, pixelCrop.height * scaleFactor, 0, 0, pixelCrop.width * scaleFactor, pixelCrop.height * scaleFactor);
    // As Base64 string
    // return croppedCanvas.toDataURL(imageType)
    // As a blob
    return new Promise((resolve) => {
        croppedCanvas.toBlob((file) => {
            resolve(URL.createObjectURL(file));
        }, imageType);
    });
}
exports.default = getCroppedImg;
