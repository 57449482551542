"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    body: {
        flex: 1,
        paddingHorizontal: (0, scale_1.scale)(16),
    },
    title: {
        marginTop: (0, scale_1.scale)(16),
        fontSize: (0, scale_1.moderateScale)(16),
        lineHeight: (0, scale_1.moderateScale)(24),
        textAlign: 'center',
        color: '#292A33',
    },
    description: {
        marginTop: (0, scale_1.scale)(16),
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
        color: '#292A33',
    },
    button: {
        marginTop: (0, scale_1.scale)(28),
    },
    buttonLabel: {
        fontSize: (0, scale_1.moderateScale)(15),
        lineHeight: (0, scale_1.moderateScale)(22.5),
    },
});
