"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconLeft = (props) => (React.createElement(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 36 37", fill: "none" },
    React.createElement(react_native_svg_1.Mask, { id: "mask0_7750_8140", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "36", height: "37" },
        React.createElement(react_native_svg_1.Rect, { y: "0.5", width: "36", height: "36", fill: "white" })),
    React.createElement(react_native_svg_1.G, { mask: "url(#mask0_7750_8140)" },
        React.createElement(react_native_svg_1.Path, { d: "M4.96167 31.2478V5.74783H20.7116V7.9978H7.2116V28.9978H28.2116V15.4978H30.4616V31.2478H4.96167ZM25.7886 13.4208V10.4208H22.7886V8.1709H25.7886V5.1709H28.0385V8.1709H31.0385V10.4208H28.0385V13.4208H25.7886ZM9.83667 25.6228H25.7019L20.7693 19.0459L16.5578 24.5266L13.5578 20.6902L9.83667 25.6228Z", fill: "#FEFEFE" }))));
exports.default = IconLeft;
